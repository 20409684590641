import { get, post } from "helpers/api_helper";
import { getLoggedInUser } from "helpers/fakebackend_helper";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Table, Row, Col, Container, Button, Modal, ModalBody, FormGroup,
    Label, Input, Pagination, PaginationItem, PaginationLink,
    Alert
} from "reactstrap";

const AddYearlyExpense = () => {
    const navigate = useNavigate();
    document.title = "Add Yearly Expense";

    const [Agency, setAgency] = useState('');
    const [ApprovalUserID, setApprovalUserID] = useState();
    const [TotalExpense, setTotalExpense] = useState(0);
    const [BudgetYear, setBudgetYear] = useState();

    const [headCosts, setHeadCosts] = useState([]);

    const [costError, setCostError] = useState("");

    const headCostList = [
        "MoHAIT - 2300123234",
        "MoHAFIN - 23001236578",
        "MoHACON - 2300123455",
        "MoHAMAR - 2300124356"
    ]

    const [headCostName, setHeadCostName] = useState();
    const [headCostAmount, setHeadCostAmount] = useState();

    useEffect(()=>{
        setTimeout(()=>{
            setCostError("");
        },3000);
    },[costError]);

    const currentYear = new Date().getFullYear();
    const years = [];

    for (let i = 0; i < 20; i++) {
        years.push(currentYear + i);
    }

    const [psList, setPsList] = useState([]);

    const getPS = async () => {
        const res = await get('user/find-by-role-name/Permanent Secretary');
        setPsList(res);
        setApprovalUserID(res && res.length > 0 ? res[0].UserID : 0);
    }

    useEffect(()=>{
        getPS();
    },[]);

    const handleSubmit = async () => {
        const user = getLoggedInUser();
        const data = {
            BudgetYear,
            Agency,
            TotalExpense,
            ApprovalUserID,
            ApprovalStatus: 'Pending',
            CreatedBy: user.id,
            headCosts
        }

        console.log(data);

        await post('yearlyExpense/create', data);
        navigate('/yearly-expense');
    }

    const handleHeadCost = () => {
        let totalHeadCost = headCosts.reduce((total, acc)=> total + acc.Amount, 0);
        let tempCost = parseFloat(totalHeadCost) + parseFloat(headCostAmount);
        if(tempCost > parseFloat(TotalExpense)){
            setCostError(`Expense cannot exceed ${TotalExpense}`);
            return 
        }
        
        const data = {
            Name : headCostName,
            Amount : headCostAmount
        }

        setHeadCosts(prevHeadCosts => [...prevHeadCosts, data]);
    }

    const handleRemoveHeadCostByIndex = (indexToRemove) => {
        setHeadCosts(prevHeadCosts => prevHeadCosts.filter((_, index) => index !== indexToRemove));
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container className="mt-4">
                    <Container fluid={true} style={{ backgroundColor: "white", padding: "20px" }}>
                        {/* <h5>Single Yearly Expense</h5> */}

                        <Row className="mb-4 mt-4">
                            <Col sm={4}>
                                <FormGroup className="d-flex align-items-center text-nowrap">
                                    <Label for="selectAgency" className="me-4">Budget Year</Label>
                                    <Input type="select"
                                        placeholder="Enter Type of ID"
                                        id="selectAgency"
                                        onChange={(e)=>setBudgetYear(e.target.value)}
                                        value={BudgetYear}
                                        required>
                                            <option selected>Select Year</option>
                                            {years.map(year => (
                                                <option key={year} value={year}>
                                                    {year}
                                                </option>
                                            ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                            {/* <Col sm={4}>
                                <FormGroup className="d-flex align-items-center text-nowrap">
                                    <Label for="assignedPS" className="me-4">Assigned PS</Label>
                                    <Input type="select"
                                        placeholder="Assigned PS"
                                        id="assignedPS"
                                        onChange={(e)=>setApprovalUserID(e.target.value)}
                                        value={ApprovalUserID}
                                        required>
                                            <option selected>Select</option>
                                        {psList.map((ps)=>(
                                            <option value={ps.UserID}>{ps.Name}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </Col> */}
                            <Col sm={4}>
                                <FormGroup className="d-flex align-items-center text-nowrap">
                                    <Label for="totalExpense" className="me-4">Total Budget</Label>
                                    <Input type="text"
                                        placeholder="Total Budget"
                                        id="totalExpense"
                                        onChange={(e)=> setTotalExpense(e.target.value)}
                                        required>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mb-4 mt-4">
                            <h5 className="mb-4">Head Cost</h5>
                            <Col sm={4}>
                                <FormGroup className="d-flex align-items-center text-nowrap">
                                    <Label for="headCost" className="me-4">Select Head Cost</Label>
                                    <Input type="select"
                                        placeholder="Head Cost"
                                        id="headCost"
                                        onChange={(e)=> setHeadCostName(e.target.value)}
                                        value={headCostName}
                                        required>
                                            <option>Select</option>
                                            {headCostList.map((hostCost)=>(
                                                <option value={hostCost}>{hostCost}</option>
                                            ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup className="d-flex align-items-center text-nowrap">
                                    <Label for="amount" className="me-4">Amount</Label>
                                    <Input type="number"
                                        placeholder="Amount"
                                        id="Amount"
                                        onChange={(e)=> setHeadCostAmount(e.target.value)}
                                        value={headCostAmount}
                                        required>
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup className="d-flex align-items-center text-nowrap">
                                    <button className="btn btn-primary" onClick={handleHeadCost}>Add Headcost</button>
                                </FormGroup>
                            </Col>
                        </Row>
                        {costError &&
                            <Alert color="danger">{costError}</Alert>
                        }
                        <Row>
                            <Col sm={6}>
                                <Table bordered responsive>
                                    <thead>
                                        <tr>
                                            <th>Head Cost</th>
                                            <th>Amount</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {headCosts.map((headCost,i)=>(
                                            <tr>
                                                <td>{headCost.Name}</td>
                                                <td>${headCost.Amount}</td>
                                                <td><button className="btn btn-danger" onClick={()=>handleRemoveHeadCostByIndex(i)}>Delete</button></td>
                                            </tr>
                                        ))}
                                        
                                        {(headCosts && headCosts.length > 0) &&
                                        <>
                                            <tr>
                                                <td><b>Total Budget Summary</b></td>
                                                <td>${headCosts.reduce((total, acc) => total + parseFloat(acc.Amount), 0)}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Remaining Budget</b></td>
                                                <td>${TotalExpense - headCosts.reduce((total, acc) => total + parseFloat(acc.Amount), 0)}</td>
                                            </tr>
                                        </>
                                        }
                                    </tbody>
                                </Table></Col>
                        </Row>
                        {/* <Row>
                            <h5>Approvals</h5>
                            <Col sm={6}>
                                <Table bordered responsive>
                                    <tbody>
                                        <tr>
                                            <td>PS Approval</td>
                                            <td>Pending</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row> */}

                        <Row>                        
                            <Col sm={12}>
                                <FormGroup className="d-flex align-items-center justify-content-end text-nowrap">
                                    <button onClick={handleSubmit} className="btn btn-primary">Submit for Approval</button>
                                </FormGroup>    
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddYearlyExpense;
