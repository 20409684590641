import React, { useEffect, useMemo, useState } from "react";
import {
  Table, Row, Col, Container, Button, Modal, ModalBody, FormGroup,
  Label, Input, Pagination, PaginationItem, PaginationLink, ModalHeader,
  Tooltip
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getLoggedInUserRole } from "helpers/fakebackend_helper";
import { get, post, put } from "helpers/api_helper";
import DataTable from "react-data-table-component";

const Vendor = () => {
  document.title = "Vendor";

  const getValue = (row, accessor) => {
    if (typeof accessor === "function") {
      return accessor(row);
    }
    return row[accessor] || "";
  };

  const [data, setData] = useState([]);
  const [role, setRole] = useState({});

  const [Name, setName] = useState('');
  const [Address, setAddress] = useState('');
  const [ContactPerson, setContactName] = useState('');
  const [Contact, setContact] = useState('');
  const [Email, setEmail] = useState('');
  const [RTP_ID, setRtpId] = useState('');

  const Password = "zxcasd123";

  const [add_vendor_modal, setadd_vendor_modal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false); // Track if it's edit mode
  const [selectedVendor, setSelectedVendor] = useState(null); // Track the selected employee for editing

  const fetchRole = async () => {
    const role = await get(`role/find-by-name/Vendor`);
    setRole(role);
  }

  const fetchVendor = async () => {
    const data = await get(`user/find-by-role-name/Vendor`);
    setData(data);
  }

  const addVendor = async () => {
    const data = {
      Name, Address, ContactPerson, Contact, Email, RTP_ID, Password, RoleID: role.RoleID
    }

    await post(`user/create`, data);
    setadd_vendor_modal(false);
    fetchVendor();
  }

  const editVendor = async () => {
    const data = {
      Name, Address, ContactPerson, Contact, Email, RTP_ID, Password
    };
    await put(`user/update/${selectedVendor.UserID}`, data); // Example endpoint
    setadd_vendor_modal(false);
    fetchVendor();
  };

  useEffect(() => {
    fetchRole();
    fetchVendor();
  }, [])

  const columns = useMemo(
    () => [
      {
        name: 'Vendor ID',
        selector: (row) => row.UserPrefixId,
        sortable: true,
        enableSorting: true,
      },
      {
        name: 'Vendor Name',
        selector: (row) => row.Name,
        sortable: true,
        enableSorting: true,
      },
      {
        name: 'Vendor Address',
        selector: (row) => row.Address,
        sortable: true,
        enableSorting: true,
      },
      {
        name: 'Vendor Contact Name',
        selector: (row) => row.ContactPerson,
        sortable: true,
        enableSorting: true,
      },
      {
        name: 'Vendor Contact',
        selector: (row) => row.Contact,
        sortable: true,
        enableSorting: true,
      },
      {
        name: 'Vendor Email',
        selector: (row) => row.Email,
        sortable: true,
        enableSorting: true,
      },
      {
        name: 'RTP ID',
        selector: (row) => row.RTP_ID,
        sortable: true,
        enableSorting: true,
      },
      {
        name: "Action",
        cell: (row) => (
          <Button
            color="primary"
            size="sm"
            onClick={() => handleViewDetails(row)}
          >
            View Details
          </Button>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ],
    []
  );

  // const columns = useMemo(
  //   () => [
  //     {
  //       header: 'Vendor ID',
  //       accessorKey: 'UserPrefixId',
  //       enableColumnFilter: false,
  //       enableSorting: true,
  //     },
  //     {
  //       header: 'Vendor Name',
  //       accessorKey: 'Name',
  //       enableColumnFilter: false,
  //       enableSorting: true,
  //     },
  //     {
  //       header: 'Vendor Address',
  //       accessorKey: 'Designation',
  //       enableColumnFilter: false,
  //       enableSorting: true,
  //     },
  //     // {
  //     //   header: 'Vendor Contact',
  //     //   accessorKey: 'vendorcontact',
  //     //   enableColumnFilter: false,
  //     //   enableSorting: true,
  //     // },
  //     {
  //       header: 'Vendor Email',
  //       accessorKey: 'Email',
  //       enableColumnFilter: false,
  //       enableSorting: true,
  //     },
  //     // {
  //     //   header: 'RTP ID',
  //     //   accessorKey: 'rtpid',
  //     //   enableColumnFilter: false,
  //     //   enableSorting: true,
  //     // },
  //     {
  //       header: 'Action',
  //       accessorKey: 'action',  // Ensure this matches the "action" accessor
  //       enableColumnFilter: false,
  //       enableSorting: false,
  //       Cell: ({ row }) => (
  //         <Button
  //           color="primary"
  //           size="sm"
  //           onClick={() => handleViewDetails(row.original)} // Ensure this function exists
  //         >
  //           View Details
  //         </Button>
  //       ),
  //     },
  //   ],
  //   []
  // );

  // const data = [
  //   {
  //     vendorid: "V001",
  //     vendorname: "ABC Supplies",
  //     vendoraddress: "123 Main St, Springfield, IL",
  //     vendorcontact: "John Doe",
  //     vendoremail: "john.doe@abcsupplies.com",
  //     rtpid: "RTP001",
  //   },
  //   {
  //     vendorid: "V002",
  //     vendorname: "XYZ Technologies",
  //     vendoraddress: "456 Oak Rd, Metropolis, NY",
  //     vendorcontact: "Jane Smith",
  //     vendoremail: "jane.smith@xyztech.com",
  //     rtpid: "RTP002",
  //   },
  //   {
  //     vendorid: "V003",
  //     vendorname: "Tech Innovators",
  //     vendoraddress: "789 Pine Ave, Gotham City, NJ",
  //     vendorcontact: "Alice Johnson",
  //     vendoremail: "alice.johnson@techinnovators.com",
  //     rtpid: "RTP003",
  //   },
  //   {
  //     vendorid: "V004",
  //     vendorname: "Global Distributors",
  //     vendoraddress: "101 Maple St, Star City, CA",
  //     vendorcontact: "Bob Brown",
  //     vendoremail: "bob.brown@globaldist.com",
  //     rtpid: "RTP004",
  //   },
  //   {
  //     vendorid: "V005",
  //     vendorname: "Supply Hub",
  //     vendoraddress: "202 Cedar Blvd, Smallville, TX",
  //     vendorcontact: "Charlie Davis",
  //     vendoremail: "charlie.davis@supplyhub.com",
  //     rtpid: "RTP005",
  //   },
  //   {
  //     vendorid: "V006",
  //     vendorname: "Eagle Enterprises",
  //     vendoraddress: "303 Birch Dr, Central City, FL",
  //     vendorcontact: "David Clark",
  //     vendoremail: "david.clark@eagleent.com",
  //     rtpid: "RTP006",
  //   },
  //   {
  //     vendorid: "V007",
  //     vendorname: "Innovative Goods",
  //     vendoraddress: "404 Elm St, Metropolis, NY",
  //     vendorcontact: "Eva Lewis",
  //     vendoremail: "eva.lewis@innovativegoods.com",
  //     rtpid: "RTP007",
  //   },
  //   {
  //     vendorid: "V008",
  //     vendorname: "Prime Suppliers",
  //     vendoraddress: "505 Birchwood Ln, Riverdale, NJ",
  //     vendorcontact: "Frank Harris",
  //     vendoremail: "frank.harris@primesuppliers.com",
  //     rtpid: "RTP008",
  //   },
  //   {
  //     vendorid: "V009",
  //     vendorname: "Ultimate Ventures",
  //     vendoraddress: "606 Pine Rd, Liberty City, CA",
  //     vendorcontact: "Grace Martin",
  //     vendoremail: "grace.martin@ultimateventures.com",
  //     rtpid: "RTP009",
  //   }
  // ];


  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const paginatedData = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleViewDetails = (rowData) => {
    console.log("View details for:", rowData);
    setIsEditMode(true);
    setSelectedVendor(rowData);
    populateFields(rowData);
    setadd_vendor_modal(true);
  };

  const clearFields = () => {
    setName('');
    setEmail('');
    // setPassword('');
    // setDepartment('');
    // setDesignation('');
  };

  const populateFields = (rowData) => {
    setName(rowData.Name);
    setEmail(rowData.Email);
    setAddress(rowData.Address);
    setContactName(rowData.ContactPerson);
    setContact(rowData.Contact);
    setRtpId(rowData.RTP_ID);
    // setDepartment(rowData.Department);
    // setDesignation(rowData.Designation);
  };

  const add_vendor = () => {
    setIsEditMode(false);
    clearFields();
    setadd_vendor_modal(!add_vendor_modal);
    removeBodyCss();
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    setUserRole(getLoggedInUserRole());
  }, []);

  const [searchText, setSearchText] = useState("");

  const filteredData = searchText.trim() === ""
    ? data // If searchText is empty, return all data without filtering
    : data.filter(
      (item) =>
        item?.UserPrefixId?.toString().includes(searchText) ||
        item?.Name?.toLowerCase().includes(searchText.toLowerCase()) ||
        item?.Address?.toLowerCase().includes(searchText.toLowerCase()) ||
        item?.ContactPerson?.toLowerCase().includes(searchText.toLowerCase()) ||
        item?.Contact?.toLowerCase().includes(searchText.toLowerCase()) ||
        item?.Email?.toLowerCase().includes(searchText.toLowerCase()) ||
        item?.RTP_ID?.toLowerCase().includes(searchText.toLowerCase())
    );

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container className="mt-4" fluid={true} style={{ backgroundColor: "white", padding: "20px" }}>
          <Row>
            <Col sm={6}><h5>Vendor<i id="pageHeading" style={{ marginLeft: "5px", fontSize: "0.8em" }} className="fa fa-info-circle"></i>
            </h5>

              <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                target="pageHeading"
                toggle={toggleTooltip}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras dignissim rhoncus turpis.
              </Tooltip>
            </Col>
            {userRole == 'Finance Officer' && <Col sm={6} className="text-end"><Button color="primary" onClick={add_vendor}> Add Vendor </Button></Col>}
          </Row>

          <Modal isOpen={add_vendor_modal} toggle={() => setadd_vendor_modal(!add_vendor_modal)} size="lg">
            <ModalHeader className="mt-0" toggle={() => setadd_vendor_modal(!add_vendor_modal)}>{isEditMode ? "Update Vendor" : "Add Vendor"}</ModalHeader>
            <ModalBody>
              <Row>
                <Col sm={6}>
                  <FormGroup className="d-flex align-items-center text-nowrap">
                    <Label for="" className="me-4 w-75">Vendor Name</Label>
                    <Input
                      type="text"
                      id=""
                      placeholder="Vendor Name"
                      onChange={(e) => setName(e.target.value)}
                      value={Name}
                    />
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="d-flex align-items-center text-nowrap">
                    <Label for="" className="me-4 w-75">Vendor Address</Label>
                    <Input
                      type="text"
                      id=""
                      placeholder="Vendor Address"
                      onChange={(e) => setAddress(e.target.value)}
                      value={Address}
                    />
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="d-flex align-items-center text-nowrap">
                    <Label for="" className="me-4 w-75">Vendor Contact Name</Label>
                    <Input
                      type="text"
                      id=""
                      placeholder="Vendor Contact Name"
                      onChange={(e) => setContactName(e.target.value)}
                      value={ContactPerson}
                    />
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="d-flex align-items-center text-nowrap">
                    <Label for="" className="me-4 w-75">Vendor Contact Phone</Label>
                    <Input
                      type="text"
                      id=""
                      placeholder="Vendor Contact Phone"
                      onChange={(e) => setContact(e.target.value)}
                      value={Contact}
                    />
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className="d-flex align-items-center text-nowrap">
                    <Label for="" className="me-4 w-75">Vendor Contact Email</Label>
                    <Input
                      type="text"
                      id=""
                      placeholder="Vendor Contact Email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={Email}
                    />
                  </FormGroup>
                </Col>
                {/* <Col sm={6}>
                    <FormGroup className="d-flex align-items-center text-nowrap">
                      <Label for="" className="me-4 w-75">Vendor Type</Label>
                      <Input
                        type="text"
                        id=""
                        placeholder="Vendor Type"
                      />
                    </FormGroup>
                  </Col> */}
                <Col sm={6}>
                  <FormGroup className="d-flex align-items-center text-nowrap">
                    <Label for="" className="me-4 w-75">RTP ID</Label>
                    <Input
                      type="text"
                      id=""
                      placeholder="RTP ID"
                      onChange={(e) => setRtpId(e.target.value)}
                      value={RTP_ID}
                    />
                  </FormGroup>
                </Col>
                <Col sm={12} className="text-end">
                  {/* <button className="btn btn-primary mt-5" onClick={addVendor}>Submit</button> */}
                  <Button color="primary" onClick={isEditMode ? editVendor : addVendor}>
                    {isEditMode ? "Save" : "Submit"}
                  </Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal>

          <Row className="mt-4">
            <Col sm={3}>
              <div className="table-subheader">
                <span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here..."
                    onChange={(e) => {
                      setSearchText(e.target.value)
                    }}
                  />
                </span>
              </div>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={30}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                highlightOnHover
                striped
                responsive
                // subHeader
                // subHeaderAlign="left"
                // subHeaderComponent={
                //   <div className="table-subheader">
                //     <span>
                //       <input
                //         type="text"
                //         className="form-control"
                //         placeholder="Search here..."
                //         onChange={(e) => {
                //           setSearchText(e.target.value)
                //         }}
                //       />
                //     </span>
                //   </div>
                // }
              />
            </Col>
          </Row>

          {/* <Row className="mb-4 mt-4">
              <Col sm={3}>
                <FormGroup className="d-flex align-items-center text-nowrap">
                  <Label for="vendorId" className="me-4">Vendor Id</Label>
                  <Input
                    type="text"
                    id="vendorId"
                    placeholder="Vendor Id"
                  />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <FormGroup className="d-flex align-items-center text-nowrap">
                  <Label for="vendorName" className="me-4">Vendor Name</Label>
                  <Input
                    type="text"
                    id="vendorName"
                    placeholder="Vendor Name"
                  />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <FormGroup className="d-flex align-items-center text-nowrap">
                  <Label for="vendorEmail" className="me-4">Vendor Email</Label>
                  <Input
                    type="text"
                    id="vendorEmail"
                    placeholder="Vendor Email"
                  />
                </FormGroup>
              </Col>
              <Col sm={2}>
                <Button color="primary">
                  Search
                </Button>
              </Col>
            </Row>
            <Table bordered responsive>
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th key={index}>{column.header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {columns.map((column, colIndex) => (
                      <td key={colIndex}>
                        {column.accessorKey === "action" ? (
                          <Button color="primary" size="sm" onClick={() => handleViewDetails(row)}>
                            View Details
                          </Button>
                        ) : (
                          getValue(row, column.accessorKey)
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table> */}
          <Row>

            <Pagination style={{ float: "right" }}>
              <PaginationItem disabled={currentPage == 1}>
                <PaginationLink previous onClick={handlePrevPage} />
              </PaginationItem>

              {[...Array(totalPages)].map((_, index) => (
                <PaginationItem active={currentPage == index + 1} key={index}>
                  <PaginationLink onClick={() => setCurrentPage(index + 1)}>
                    {index + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}

              <PaginationItem disabled={currentPage == totalPages}>
                <PaginationLink next onClick={handleNextPage} />
              </PaginationItem>
            </Pagination>

          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

// const Vendor = () => {
//   document.title = "Vendor";

//   const [data, setData] = useState([]);
//   const [role, setRole] = useState({});
//   const [selectedVendor, setSelectedVendor] = useState(null); // State to hold selected vendor details
//   const [modalMode, setModalMode] = useState("add"); // Can be "add" or "view"

//   const [Name, setName] = useState("");
//   const [Email, setEmail] = useState("");
//   const [Password, setPassword] = useState("");
//   const [Department, setDepartment] = useState("");
//   const [Designation, setDesignation] = useState("");

//   const [add_employee_modal, setadd_employee_modal] = useState(false);

//   const fetchRole = async () => {
//     const role = await get(`role/find-by-name/Vendor`);
//     setRole(role);
//   };

//   const fetchVendor = async () => {
//     const data = await get(`user/find-by-role-name/Vendor`);
//     setData(data);
//   };

//   const addVendor = async () => {
//     const data = {
//       Name,
//       Email,
//       Password,
//       Designation,
//       RoleID: role.RoleID,
//     };

//     await post(`user/create`, data);
//     setadd_employee_modal(false);
//     fetchVendor();
//   };

//   const openModal = (mode, vendor = null) => {
//     setModalMode(mode);
//     if (mode === "view" && vendor) {
//       setSelectedVendor(vendor);
//       setName(vendor.Name || "");
//       setEmail(vendor.Email || "");
//       setDesignation(vendor.Designation || "");
//     } else {
//       // Reset fields for add mode
//       setSelectedVendor(null);
//       setName("");
//       setEmail("");
//       setDesignation("");
//       setPassword(""); // Password only for new vendor
//     }
//     setadd_employee_modal(true);
//   };

//   const handleViewDetails = (vendor) => {
//     openModal("view", vendor);
//   };

//   useEffect(() => {
//     fetchRole();
//     fetchVendor();
//   }, []);

//   const columns = useMemo(
//     () => [
//       {
//         name: "Vendor ID",
//         selector: (row) => row.UserPrefixId,
//         sortable: true,
//         enableSorting: true,
//       },
//       {
//         name: "Vendor Name",
//         selector: (row) => row.Name,
//         sortable: true,
//         enableSorting: true,
//       },
//       {
//         name: "Vendor Address",
//         selector: (row) => row.Designation,
//         sortable: true,
//         enableSorting: true,
//       },
//       {
//         name: "Vendor Email",
//         selector: (row) => row.Email,
//         sortable: true,
//         enableSorting: true,
//       },
//       {
//         name: "Action",
//         cell: (row) => (
//           <Button color="primary" size="sm" onClick={() => handleViewDetails(row)}>
//             View Details
//           </Button>
//         ),
//         ignoreRowClick: true,
//         allowOverflow: true,
//         button: true,
//       },
//     ],
//     []
//   );

//   return (
//     <React.Fragment>
//       <div className="page-content">
//         <Container className="mt-4">
//           <Container fluid={true} style={{ backgroundColor: "white", padding: "20px" }}>
//             <Row>
//               <Col sm={6}>
//                 <h5>
//                   Vendor
//                   <i
//                     id="pageHeading"
//                     style={{ marginLeft: "5px", fontSize: "0.8em" }}
//                     className="fa fa-info-circle"
//                   ></i>
//                 </h5>
//               </Col>
//               <Col sm={6} className="text-end">
//                 <Button color="primary" onClick={() => openModal("add")}>
//                   Add Vendor
//                 </Button>
//               </Col>
//             </Row>

//             <Modal isOpen={add_employee_modal} toggle={() => setadd_employee_modal(false)} size="lg">
//               <ModalHeader
//                 className="mt-0"
//                 toggle={() => setadd_employee_modal(false)}
//               >
//                 {modalMode === "add" ? "Add Vendor" : "Vendor Details"}
//               </ModalHeader>
//               <ModalBody>
//                 <Row>
//                   <Col sm={6}>
//                     <FormGroup className="d-flex align-items-center text-nowrap">
//                       <Label className="me-4 w-75">Vendor Name</Label>
//                       <Input
//                         type="text"
//                         placeholder="Vendor Name"
//                         onChange={(e) => setName(e.target.value)}
//                         value={Name}
//                         disabled={modalMode === "view"} // Disable input in view mode
//                       />
//                     </FormGroup>
//                   </Col>
//                   <Col sm={6}>
//                     <FormGroup className="d-flex align-items-center text-nowrap">
//                       <Label className="me-4 w-75">Vendor Address</Label>
//                       <Input
//                         type="text"
//                         placeholder="Vendor Address"
//                         onChange={(e) => setDesignation(e.target.value)}
//                         value={Designation}
//                         disabled={modalMode === "view"}
//                       />
//                     </FormGroup>
//                   </Col>
//                   <Col sm={6}>
//                     <FormGroup className="d-flex align-items-center text-nowrap">
//                       <Label className="me-4 w-75">Vendor Email</Label>
//                       <Input
//                         type="text"
//                         placeholder="Vendor Email"
//                         onChange={(e) => setEmail(e.target.value)}
//                         value={Email}
//                         disabled={modalMode === "view"}
//                       />
//                     </FormGroup>
//                   </Col>
//                   {modalMode === "add" && (
//                     <Col sm={6}>
//                       <FormGroup className="d-flex align-items-center text-nowrap">
//                         <Label className="me-4 w-75">Password</Label>
//                         <Input
//                           type="password"
//                           placeholder="Password"
//                           onChange={(e) => setPassword(e.target.value)}
//                           value={Password}
//                         />
//                       </FormGroup>
//                     </Col>
//                   )}
//                   <Col sm={12} className="text-end">
//                     {modalMode === "add" && (
//                       <button className="btn btn-primary mt-5" onClick={addVendor}>
//                         Submit
//                       </button>
//                     )}
//                   </Col>
//                 </Row>
//               </ModalBody>
//             </Modal>

//             <Row className="mt-4">
//               <Col>
//                 <DataTable
//                   columns={columns}
//                   data={data}
//                   pagination
//                   highlightOnHover
//                   striped
//                   responsive
//                 />
//               </Col>
//             </Row>
//           </Container>
//         </Container>
//       </div>
//     </React.Fragment>
//   );
// };


export default Vendor;
