import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label, Input, Button, Row, Col } from "reactstrap";

const AddVisitorForm = ({ onSubmit, toggle, visitorData }) => {
    const [formData, setFormData] = useState({
        ExternalIDType: "Passport",
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (onSubmit) {
            onSubmit(formData); // Pass form data to the parent
        }
    };

    const handleCancel = () => {
        if (toggle) {
            toggle(); // Close the modal by calling the toggle function
        }
    };

    useEffect(() => {
        if(visitorData){
            let visitorPayload = {
                VisitorID: visitorData.VisitorID,
                VisitorName: visitorData.VisitorName,
                VisitorAddress: visitorData.VisitorAddress,
                ExternalID: visitorData.ExternalID,
                ExternalIDType: visitorData.ExternalIDType,
            }
            setFormData(visitorPayload);
        }
      }, []);

    return (
        <>
            {/* <h5>{visitorData? "Edit Visitor" : "Add Visitor"}</h5>

            <hr /> */}

            <h5>Visitor Details</h5>

            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Row style={{marginTop: "20px"}}>
                        <Col sm={6}>
                            <Label for="name">Visitor Name</Label>
                            <Input
                                type="text"
                                id="VisitorName"
                                placeholder="Enter Visitor Name"
                                value={formData.VisitorName}
                                onChange={handleChange}
                                required
                            />
                        </Col>

                        <Col sm={6}>
                            <Label for="address">Visitor Address</Label>
                            <Input
                                type="text"
                                id="VisitorAddress"
                                placeholder="Enter Visitor Address"
                                value={formData.VisitorAddress}
                                onChange={handleChange}
                                required
                            />
                        </Col>
                    </Row>

                    <Row style={{marginTop: "20px"}}>
                        

                        <Col sm={6}>
                            <Label for="id">ID Number</Label>
                            <Input
                                type="text"
                                id="ExternalID"
                                placeholder="Enter ID Number"
                                value={formData.ExternalID}
                                onChange={handleChange}
                                required
                            />
                        </Col>
                        

                        <Col sm={6}>
                            <Label for="ExternalIDType">Type of ID</Label>
                            <Input type="select"
                                placeholder="Enter Type of ID"
                                id="ExternalIDType"
                                value={formData.ExternalIDType}
                                onChange={handleChange}
                                required>
                                    <option>Passport</option>
                                    <option>National ID</option>
                                    <option>Driving License</option>
                            </Input>
                        </Col>
                    </Row>
                </FormGroup>
                
                <div style={{float:"right"}}>
                    <Button color="primary" type="submit" style={{marginRight:"10px"}}>
                        {visitorData? "Save" : "Add"}
                    </Button>
                    <Button color="secondary" type="button" onClick={handleCancel}>
                        Cancel
                    </Button>
                </div>

            </Form>
        </>
    );
};

export default AddVisitorForm;
