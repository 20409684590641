import React, {useState} from 'react';
import { Row, Col, Label, Input, Button, FormGroup } from "reactstrap";

const CheckoutVisitorForm = ({toggle, visitorData, onSubmit}) => {

  const [VisitorPassStatus, setVisitorPassStatus] = useState('');
  const [CheckoutAuthority, setCheckoutAuthority] = useState('Help Desk Officer 1');
  const [CheckoutOfficerRank, setCheckoutOfficerRank] = useState('');

  const clickCheckout = () => {
    console.log('CheckOut clicked!', { VisitorPassStatus, CheckoutAuthority });

    let payload = {
        VisitorPass:            visitorData.VisitorPass,
        VisitorPassStatus:      VisitorPassStatus,
        CheckoutAuthority:      CheckoutAuthority,
        CheckoutOfficerRank:    CheckoutOfficerRank,
        CheckoutDate:           new Date().toLocaleDateString('en-US'),
        CheckoutTime:           new Date().toLocaleTimeString('en-US')
    }
    onSubmit(payload);
    // toggle();
  };

  return (
    <>
        <h5>Check-Out</h5>

        <hr />

        <h5>Visitor Details</h5>

        <Row>
            <Col sm={4}>
                <strong>Visitor ID:</strong> {visitorData?.VisitorID.toString().padStart(5, '0') || ""}
            </Col>

            <Col sm={4}>
                <strong>Visitor Name:</strong> {visitorData?.visitor?.VisitorName || ""}
            </Col>

            <Col sm={4}>
                <strong>Approval Authority:</strong> {visitorData?.approvals?.ApprovalAuthority || ""}
            </Col>
        </Row>

        <Row className='mt-2'>
            <Col sm={4}>
                <strong>Approval Type:</strong> {visitorData?.approvals?.ApprovalType || ""}
            </Col>

            <Col sm={4}>
                <strong>Building Access:</strong> {visitorData?.approvals?.BuildingAccess || ""}
            </Col>

            <Col sm={4}>
                <strong>Visit Purpose:</strong> {visitorData?.approvals?.VisitPurpose || ""}
            </Col>

        </Row>


        <h5 className='mt-4'>Check-Out Details</h5>

        <Col>
            <Row className="mb-4">
                <Col md={2}>
                <FormGroup>
                    <Label for="VisitorPass" className='mt-1 me-3'>Pass Returned:</Label>
                    <Input  type="checkbox"
                            id="VisitorPassStatus"
                            value={VisitorPassStatus}
                            onChange={(e) => setVisitorPassStatus(e.target.checked)}
                            style={{
                                width: "20px",
                                height: "20px",
                                accentColor: "blue", // Adds a custom color to the checkbox
                                border: "1px solid black", // Increases border visibility
                            }}/>
                </FormGroup>
                </Col>
                <Col md={5}>
                <FormGroup>
                    <Row>
                        <Col sm={4}>
                            <Label for="CheckinAuthority" className='mt-1'>Check-Out Officer:</Label>
                        </Col>
                        <Col sm={8}>
                        <Input type="select"
                            id="CheckinAuthority"
                            value={CheckoutAuthority}
                            onChange={(e) => setCheckoutAuthority(e.target.value)}>
                            <option>Help Desk Officer 1</option>
                            <option>Help Desk Officer 2</option>
                    </Input>
                        </Col>
                    </Row>

                </FormGroup>
                </Col>

                {/* <Col sm={5}>
                    <FormGroup>
                        <Row>
                        <Col sm={3}>
                            <Label for="CheckoutOfficerRank">Officer Rank: </Label>
                        </Col>
                        <Col sm={9}>
                            <Input
                                type="text"
                                id="CheckoutOfficerRank"
                                placeholder="Enter Checkout Officer Rank"
                                value={CheckoutOfficerRank}
                                onChange={(e) => setCheckoutOfficerRank(e.target.value)}
                            />
                        </Col>
                        </Row>
                    </FormGroup>
                </Col> */}
            </Row>
            <Button color="primary" style={{float:"right"}} onClick={clickCheckout}>
                Check Out
            </Button>
        </Col>
    </>
  );
};

export default CheckoutVisitorForm;
