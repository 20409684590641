import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Tooltip, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { getLoggedInUserRole } from "helpers/fakebackend_helper";
import { get } from "../../../src/helpers/api_helper";
import DataTable from "react-data-table-component";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const YearlyExpense = () => {
  document.title = "Yearly Expense";

  const [userRole, setUserRole] = useState("");
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    setUserRole(getLoggedInUserRole());
  }, []);

  const fetchYearlyExpense = async () => {
    try {
      let response = await get("yearlyExpense/find");
      setData(response);
    } catch (error) {
      console.error("Error fetching yearly expense:", error);
    }
  };

  useEffect(() => {
    fetchYearlyExpense();
  }, []);

  const filteredData = searchText.trim() === ""
    ? data
    : data.filter(
      (item) =>
        item?.Ministry?.toLowerCase().includes(searchText.toLowerCase()) ||
        item?.TotalExpense?.toString().includes(searchText) ||
        item?.approvalUser?.Name
          .toLowerCase()
          .includes(searchText.toLowerCase())
    );


  const columns = [
    {
      name: "Ministry",
      selector: (row) => "MOHA", // Replace with actual ministry field if available
      sortable: true,
    },
    {
      name: "Head Cost",
      selector: (row) =>
        row.headCost && row.headCost.length > 0
          ? row.headCost.reduce(
            (total, head) => total + parseFloat(head.Amount),
            0
          )
          : 0,
      sortable: true,
    },
    {
      name: "Total Yearly Expense",
      selector: (row) => row.TotalExpense,
      sortable: true,
    },
    {
      name: "Assigned PS",
      selector: (row) => row.approvalUser?.Name || "",
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <Button
          color="primary"
          size="sm"
          onClick={() => handleViewDetails(row)}
        >
          View Details
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleViewDetails = (rowData) => {
    console.log("View details for:", rowData);
    navigate(`/yearly-expense/${rowData.YearlyExpenseID}`);
  };

  const handleAddExpenseClick = () => {
    navigate("/add-yearly-expense");
  };

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const paginatedData = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container className="mt-4" fluid={true} style={{ backgroundColor: "white", padding: "20px" }}>

          <Row>
            {/* <Col sm={6}>
              <Breadcrumbs title="Voucher Management" breadcrumbItem="Yearly Expense" />
              <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                target="pageHeading"
                toggle={toggleTooltip}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras dignissim rhoncus turpis.
              </Tooltip>
            </Col> */}
            
            <Col sm={6}>
              <h5>Yearly Expense
                <i id="pageHeading" style={{ marginLeft: "5px", fontSize: "0.8em" }} className="fa fa-info-circle"></i>
              </h5>

              <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                target="pageHeading"
                toggle={toggleTooltip}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras dignissim rhoncus turpis.
              </Tooltip>
            </Col>
            {userRole === "Finance Officer" && (
              <Col sm={6} className="text-end">
                <Button color="primary" onClick={handleAddExpenseClick}>
                  Add Yearly Expense
                </Button>
              </Col>
            )}
          </Row>

          <Row className="mt-4">
            <Col sm={3}>
              <div className="table-subheader">
                <span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here..."
                    onChange={(e) => {
                      setSearchText(e.target.value)
                    }}
                  />
                </span>
              </div>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={30}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                highlightOnHover
                striped
                responsive
                // subHeader
                // subHeaderAlign="left"
                // subHeaderComponent={
                //   <div className="table-subheader">
                //     <span>
                //       <input
                //         type="text"
                //         className="form-control"
                //         placeholder="Search here..."
                //         onChange={(e) => {
                //           setSearchText(e.target.value)
                //         }}
                //       />
                //     </span>
                //   </div>
                // }
              />
            </Col>
          </Row>

          <Row>

            <Pagination style={{ float: "right" }}>
              <PaginationItem disabled={currentPage == 1}>
                <PaginationLink previous onClick={handlePrevPage} />
              </PaginationItem>

              {[...Array(totalPages)].map((_, index) => (
                <PaginationItem active={currentPage == index + 1} key={index}>
                  <PaginationLink onClick={() => setCurrentPage(index + 1)}>
                    {index + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}

              <PaginationItem disabled={currentPage == totalPages}>
                <PaginationLink next onClick={handleNextPage} />
              </PaginationItem>
            </Pagination>

          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default YearlyExpense;