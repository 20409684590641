import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"
import authHeader from "./jwt-token-access/auth-token-header"
import toastr from "toastr";

//pass new generated access token here
// const token = authHeader();

//apply base url for axios
// const API_URL = "https://asad.mtstechsol.com/api/"
const API_URL = process.env.REACT_APP_API_URL;
// const API_URL = "http://localhost:3000/api/";

const axiosApi = axios.create({
  baseURL: API_URL,
})

// axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`
const getToken = () => {
  const obj = JSON.parse(localStorage.getItem("authUser"))

  if (obj && obj.token) {
    return obj.token
  } else {
    return ""
  }
};

const setAuthHeader = () => {
  const token = getToken();
  if (token) {
    return { Authorization: `Bearer ${token}` };
  }
  return {};
};

axiosApi.interceptors.request.use((config) => {
  config.headers = { ...config.headers, ...setAuthHeader() }; // Attach token to every request
  return config;
}, (error) => {
  return Promise.reject(error);
});

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config })
  .then(response => response.data)
  .catch(e => toastr.error(e.response.data.error, "Error"));
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
    .catch(e => toastr.error(e.response.data.error, "Error"));
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
    .catch(e => toastr.error(e.response.data.error, "Error"));
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
    .catch(e => toastr.error(e.response.data.error, "Error"));
}

export async function postForm(url, data){
  return axiosApi.post(url, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).then(response => response.data)
  .catch(e => toastr.error(e.response.data.error, "Error"));
}

export async function putForm(url, data){
  return axiosApi.put(url, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).then(response => response.data)
  .catch(e => toastr.error(e.response.data.error, "Error"));
}
