import { get, post, put } from "helpers/api_helper";
import { getLoggedInUserRole } from "helpers/fakebackend_helper";
import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import {
    Table, Row, Col, Container, Button, Modal, ModalBody, FormGroup,
    Label, Input, Pagination, PaginationItem, PaginationLink, ModalHeader, Tooltip
} from "reactstrap";

// const Employee = () => {
//     document.title = "Employee";

//     const [data, setData] = useState([]);
//     const [role, setRole] = useState({});

//     const [Name, setName] = useState('');
//     const [Email, setEmail] = useState('');
//     const [Password, setPassword] = useState('');
//     const [Department, setDepartment] = useState('');
//     const [Designation, setDesignation] = useState('');

//     const fetchRole = async () => {
//         const role = await get(`role/find-by-name/Employee`);
//         setRole(role);
//     }

//     const fetchEmployee = async () => {
//         const data = await get(`user/find-by-role-name/Employee`);
//         setData(data);
//     }

//     const addEmployee = async () => {
//         const data = {
//             Name,Email,Password,Department,Designation, RoleID: role.RoleID
//         }

//         await post(`user/create`, data);
//         setadd_employee_modal(false);
//         fetchEmployee();
//     }

//     useEffect(()=>{
//         fetchRole();
//         fetchEmployee();
//     },[]);

//     const getValue = (row, accessor) => {
//         if (typeof accessor === "function") {
//             return accessor(row);
//         }
//         return row[accessor] || "";
//     };

//     const columns = useMemo(
//         () => [
//           {
//             name: 'Employee ID',
//             selector: (row) => row.UserPrefixId,
//             sortable: true,
//             enableSorting: true,
//           },
//           {
//             name: 'Employee Name',
//             selector: (row) =>  row.Name,
//             sortable: true,
//             enableSorting: true,
//           },
//           {
//             name: 'Designation',
//             selector: (row) => row.Designation,
//             sortable: true,
//             enableSorting: true,
//           },
//           {
//             name: 'Department',
//             selector: (row) => row.Department,
//             sortable: true,
//             enableSorting: true,
//           },
//           {
//             name: 'Email',
//             selector: (row) => row.Email,
//             sortable: true,
//             enableSorting: true,
//           },
//           {
//             name: "Action",
//             cell: (row) => (
//               <Button
//                 color="primary"
//                 size="sm"
//                 onClick={() => handleViewDetails(row)}
//               >
//                 View Details
//               </Button>
//             ),
//             ignoreRowClick: true,
//             allowOverflow: true,
//             button: true,
//           },
//         ],
//         []
//       );

//     // const columns = useMemo(
//     //     () => [
//     //         {
//     //             header: 'Employee ID',
//     //             accessor: 'UserPrefixId',
//     //             enableColumnFilter: false,
//     //             enableSorting: true,
//     //         },
//     //         {
//     //             header: 'Employee Name',
//     //             accessor: 'Name',
//     //             enableColumnFilter: false,
//     //             enableSorting: true,
//     //         },
//     //         {
//     //             header: 'Designation',
//     //             accessor: 'Designation',
//     //             enableColumnFilter: false,
//     //             enableSorting: true,
//     //         },
//     //         {
//     //             header: 'Department',
//     //             accessor: 'Department',
//     //             enableColumnFilter: false,
//     //             enableSorting: true,
//     //         },
//     //         {
//     //             header: 'Email',
//     //             accessor: 'Email',
//     //             enableColumnFilter: false,
//     //             enableSorting: true,
//     //         },
//     //         {
//     //             header: 'Action',
//     //             accessor: 'action',  // Ensure this matches the "action" accessor
//     //             enableColumnFilter: false,
//     //             enableSorting: false,
//     //             Cell: ({ row }) => (
//     //                 <Button
//     //                     color="primary"
//     //                     size="sm"
//     //                     onClick={() => handleViewDetails(row.original)} // Ensure this function exists
//     //                 >
//     //                     View Details
//     //                 </Button>
//     //             ),
//     //         },
//     //     ],
//     //     []
//     // );

//     // const data = [
//     //     {
//     //         employeeid: "E001",
//     //         employeename: "John Doe",
//     //         designation: "Software Engineer",
//     //         department: "IT",
//     //         contact: "+1 234 567 890",
//     //         email: "john.doe@example.com",
//     //         username: "johndoe",
//     //     },
//     //     {
//     //         employeeid: "E002",
//     //         employeename: "Jane Smith",
//     //         designation: "Project Manager",
//     //         department: "Management",
//     //         contact: "+1 234 567 891",
//     //         email: "jane.smith@example.com",
//     //         username: "janesmith",
//     //     },
//     //     {
//     //         employeeid: "E003",
//     //         employeename: "Samuel Clark",
//     //         designation: "Data Scientist",
//     //         department: "Data Analytics",
//     //         contact: "+1 234 567 892",
//     //         email: "samuel.clark@example.com",
//     //         username: "samuelclark",
//     //     },
//     //     {
//     //         employeeid: "E004",
//     //         employeename: "Alice Johnson",
//     //         designation: "HR Manager",
//     //         department: "HR",
//     //         contact: "+1 234 567 893",
//     //         email: "alice.johnson@example.com",
//     //         username: "alicejohnson",
//     //     },
//     //     {
//     //         employeeid: "E005",
//     //         employeename: "Bob Brown",
//     //         designation: "Marketing Specialist",
//     //         department: "Marketing",
//     //         contact: "+1 234 567 894",
//     //         email: "bob.brown@example.com",
//     //         username: "bobbrown",
//     //     },
//     //     {
//     //         employeeid: "E006",
//     //         employeename: "Charlie Davis",
//     //         designation: "Product Manager",
//     //         department: "Product",
//     //         contact: "+1 234 567 895",
//     //         email: "charlie.davis@example.com",
//     //         username: "charliedavis",
//     //     },
//     //     {
//     //         employeeid: "E007",
//     //         employeename: "David Clark",
//     //         designation: "Sales Executive",
//     //         department: "Sales",
//     //         contact: "+1 234 567 896",
//     //         email: "david.clark@example.com",
//     //         username: "davidclark",
//     //     },
//     //     {
//     //         employeeid: "E008",
//     //         employeename: "Eva Lewis",
//     //         designation: "UI/UX Designer",
//     //         department: "Design",
//     //         contact: "+1 234 567 897",
//     //         email: "eva.lewis@example.com",
//     //         username: "evalewis",
//     //     },
//     //     {
//     //         employeeid: "E009",
//     //         employeename: "Frank Harris",
//     //         designation: "Customer Support",
//     //         department: "Support",
//     //         contact: "+1 234 567 898",
//     //         email: "frank.harris@example.com",
//     //         username: "frankharris",
//     //     }
//     // ];



//     const [currentPage, setCurrentPage] = useState(1);
//     const itemsPerPage = 50;
//     const totalPages = Math.ceil(data.length / itemsPerPage);
//     const paginatedData = data.slice(
//         (currentPage - 1) * itemsPerPage,
//         currentPage * itemsPerPage
//     );

//     const handleNextPage = () => {
//         if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
//     };

//     const handlePrevPage = () => {
//         if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
//     };

//     const handleViewDetails = (rowData) => {
//         console.log("View details for:", rowData);
//         setadd_employee_modal(!add_employee_modal);
//     };

//     const [add_employee_modal, setadd_employee_modal] = useState(false);

//     const add_employee = () => {
//         setadd_employee_modal(!add_employee_modal);
//         removeBodyCss();
//     };

//     const removeBodyCss = () => {
//         document.body.classList.add("no_padding");
//     };

//     const [userRole, setUserRole] = useState('');

//     useEffect(()=>{
//         setUserRole(getLoggedInUserRole());
//     },[]);

//     const [searchText, setSearchText] = useState("");

//     const filteredData = searchText.trim() === ""
//   ? data // If searchText is empty, return all data without filtering
//   : data.filter(
//       (item) =>
//         item?.Name?.toLowerCase().includes(searchText.toLowerCase()) ||
//         item?.UserPrefixId?.toString().includes(searchText) ||
//         item?.Email?.toLowerCase().includes(searchText.toLowerCase()) ||
//         item?.Designation?.toString().includes(searchText)  ||
//         item?.Department?.toString().includes(searchText) 
//   );

//   const [tooltipOpen, setTooltipOpen] = useState(false);

//   const toggleTooltip = () => {
//     setTooltipOpen(!tooltipOpen);
//   };

//     return (
//         <React.Fragment>
//             <div className="page-content">
//                 <Container className="mt-4">
//                     <Container fluid={true} style={{ backgroundColor: "white", padding: "20px" }}>
//                         <Row>
//                             <Col sm={6}><h5>Employee<i id="pageHeading" style={{marginLeft: "5px", fontSize: "0.8em"}} className="fa fa-info-circle"></i></h5>

//               <Tooltip
//                 placement="top"
//                 isOpen={tooltipOpen}
//                 target="pageHeading"
//                 toggle={toggleTooltip}
//                 >
//                 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras dignissim rhoncus turpis.
//               </Tooltip>

//                             </Col>
//                             {userRole == 'Finance Officer' && <Col sm={6} className="text-end"><Button color="primary" onClick={add_employee}> Add Employee </Button></Col> }

//                             <Modal isOpen={add_employee_modal} toggle={add_employee} size="lg">
//                                 <ModalHeader className="mt-0" toggle={add_employee}>Add Employee</ModalHeader>
//                                 <ModalBody>
//                                     <Row>
//                                         <Col sm={6}>
//                                             <FormGroup className="d-flex align-items-center text-nowrap">
//                                                 <Label for="" className="me-4 w-50">Employee Name</Label>
//                                                 <Input
//                                                     type="text"
//                                                     id=""
//                                                     placeholder="Employee Name"
//                                                     onChange={(e)=> setName(e.target.value)}
//                                                     value={Name}
//                                                 />
//                                             </FormGroup>
//                                         </Col>
//                                         <Col sm={6}>
//                                             <FormGroup className="d-flex align-items-center text-nowrap">
//                                                 <Label for="" className="me-4 w-50">Designation</Label>
//                                                 <Input
//                                                     type="text"
//                                                     id=""
//                                                     placeholder="Designation"
//                                                     onChange={(e)=> setDesignation(e.target.value)}
//                                                     value={Designation}
//                                                 />
//                                             </FormGroup>
//                                         </Col>
//                                         {/* <Col sm={6}>
//                                             <FormGroup className="d-flex align-items-center text-nowrap">
//                                                 <Label for="" className="me-4 w-50">Contact</Label>
//                                                 <Input
//                                                     type="text"
//                                                     id=""
//                                                     placeholder="Contact"
//                                                 />
//                                             </FormGroup>
//                                         </Col> */}
//                                         <Col sm={6}>
//                                             <FormGroup className="d-flex align-items-center text-nowrap">
//                                                 <Label for="" className="me-4 w-50">Department</Label>
//                                                 <Input
//                                                     type="text"
//                                                     id=""
//                                                     placeholder="Department"
//                                                     onChange={(e)=> setDepartment(e.target.value)}
//                                                     value={Department}
//                                                 />
//                                             </FormGroup>
//                                         </Col>
//                                         <Col sm={6}>
//                                             <FormGroup className="d-flex align-items-center text-nowrap">
//                                                 <Label for="" className="me-4 w-50">Email</Label>
//                                                 <Input
//                                                     type="text"
//                                                     id=""
//                                                     placeholder="Email"
//                                                     onChange={(e)=> setEmail(e.target.value)}
//                                                     value={Email}
//                                                 />
//                                             </FormGroup>
//                                         </Col>
//                                         {/* <Col sm={6}>
//                                             <FormGroup className="d-flex align-items-center text-nowrap">
//                                                 <Label for="" className="me-4 w-50">User Name</Label>
//                                                 <Input
//                                                     type="text"
//                                                     id=""
//                                                     placeholder="User Name"
//                                                 />
//                                             </FormGroup>
//                                         </Col> */}
//                                         <Col sm={6}>
//                                             <FormGroup className="d-flex align-items-center text-nowrap">
//                                                 <Label for="" className="me-4 w-50">Password</Label>
//                                                 <Input
//                                                     type="text"
//                                                     id=""
//                                                     placeholder="Password"
//                                                     onChange={(e)=> setPassword(e.target.value)}
//                                                     value={Password}
//                                                 />
//                                             </FormGroup>
//                                         </Col>
//                                         <Col sm={12} className="text-end">
//                                             <button className="btn btn-primary mt-5" onClick={addEmployee}>Submit</button>
//                                         </Col>
//                                     </Row>
//                                 </ModalBody>
//                             </Modal>
//                         </Row>

//                         <Row className="mt-4">
//                             <Col>
//                             <DataTable
//                                 columns={columns}
//                                 data={filteredData}
//                                 pagination
//                                 highlightOnHover
//                                 striped
//                                 responsive
//                                 subHeader
//                                 subHeaderAlign="left"
//                                 subHeaderComponent={
//                                 <div className="table-subheader">
//                                     <span>
//                                     <input
//                                         type="text"
//                                         className="form-control"
//                                         placeholder="Search here..."
//                                         onChange={(e) => {
//                                         setSearchText(e.target.value)
//                                         }}
//                                     />
//                                     </span>
//                                 </div>
//                                 }
//                             />
//                             </Col>
//                         </Row>

//                         {/* <Row className="mb-4 mt-4">
//                             <Col sm={3}>
//                                 <FormGroup className="d-flex align-items-center text-nowrap">
//                                     <Label for="empId" className="me-4">Emp Id</Label>
//                                     <Input
//                                         type="text"
//                                         id="empId"
//                                         placeholder="Emp Id"
//                                     />
//                                 </FormGroup>
//                             </Col>
//                             <Col sm={3}>
//                                 <FormGroup className="d-flex align-items-center text-nowrap">
//                                     <Label for="Name" className="me-4">Name</Label>
//                                     <Input
//                                         type="text"
//                                         id="Name"
//                                         placeholder="Name"
//                                     />
//                                 </FormGroup>
//                             </Col>
//                             <Col sm={3}>
//                                 <FormGroup className="d-flex align-items-center text-nowrap">
//                                     <Label for="email" className="me-4">Email</Label>
//                                     <Input
//                                         type="text"
//                                         id="email"
//                                         placeholder="Email"
//                                     />
//                                 </FormGroup>
//                             </Col>
//                             <Col sm={2}>
//                                 <Button color="primary">
//                                     Search
//                                 </Button>
//                             </Col>
//                         </Row>
//                         <Table bordered responsive>
//                             <thead>
//                                 <tr>
//                                     {columns.map((column, index) => (
//                                         <th key={index}>{column.header}</th>
//                                     ))}
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {data.map((row, rowIndex) => (
//                                     <tr key={rowIndex}>
//                                         {columns.map((column, colIndex) => (
//                                             <td key={colIndex}>
//                                                 {column.accessor === "action" ? (
//                                                     <Button color="primary" size="sm" onClick={() => handleViewDetails(row)}>
//                                                         View Details
//                                                     </Button>
//                                                 ) : (
//                                                     getValue(row, column.accessor)
//                                                 )}
//                                             </td>
//                                         ))}
//                                     </tr>
//                                 ))}
//                             </tbody>
//                         </Table> */}
//                         <Row>

//                             <Pagination style={{ float: "right" }}>
//                                 <PaginationItem disabled={currentPage == 1}>
//                                     <PaginationLink previous onClick={handlePrevPage} />
//                                 </PaginationItem>

//                                 {[...Array(totalPages)].map((_, index) => (
//                                     <PaginationItem active={currentPage == index + 1} key={index}>
//                                         <PaginationLink onClick={() => setCurrentPage(index + 1)}>
//                                             {index + 1}
//                                         </PaginationLink>
//                                     </PaginationItem>
//                                 ))}

//                                 <PaginationItem disabled={currentPage == totalPages}>
//                                     <PaginationLink next onClick={handleNextPage} />
//                                 </PaginationItem>
//                             </Pagination>

//                         </Row>
//                     </Container>
//                 </Container>
//             </div>
//         </React.Fragment>
//     );
// };

const Employee = () => {
    document.title = "Employee";

    const [data, setData] = useState([]);
    const [role, setRole] = useState({});
    const [Name, setName] = useState('');
    const [Email, setEmail] = useState('');
    const [Password, setPassword] = useState('');
    const [Department, setDepartment] = useState('');
    const [Designation, setDesignation] = useState('');
    const [Contact, setContact] = useState('');

    const [add_employee_modal, setadd_employee_modal] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false); // Track if it's edit mode
    const [selectedEmployee, setSelectedEmployee] = useState(null); // Track the selected employee for editing

    // Table columns
    const columns = [
        {
            name: 'Employee ID',
            selector: (row) => row.UserPrefixId,
            sortable: true,
            enableSorting: true,
        },
        {
            name: "Employee Name",
            selector: (row) => row.Name,
            sortable: true,
        },
        {
            name: "Designation",
            selector: (row) => row.Designation,
            sortable: true,
        },
        {
            name: "Department",
            selector: (row) => row.Department,
            sortable: true,
        },
        {
            name: "Contact",
            selector: (row) => row.Contact,
            sortable: true,
        },
        {
            name: "Email",
            selector: (row) => row.Email,
            sortable: true,
        },


        {
            name: "Action",
            cell: (row) => (
                <Button
                    color="primary"
                    size="sm"
                    onClick={() => handleViewDetails(row)}
                >
                    View Details
                </Button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const fetchRole = async () => {
        const role = await get(`role/find-by-name/Employee`);
        setRole(role);
    };

    const fetchEmployee = async () => {
        const data = await get(`user/find-by-role-name/Employee`);
        setData(data);
    };

    const addEmployee = async () => {
        const data = {
            Name, Email, Password, Department, Designation, Contact, RoleID: role.RoleID,
        };
        await post(`user/create`, data);
        setadd_employee_modal(false);
        fetchEmployee();
    };

    const editEmployee = async () => {
        const data = {
            Name, Email, Department, Designation, Contact,
        };
        await put(`user/update/${selectedEmployee.UserID}`, data); // Example endpoint
        setadd_employee_modal(false);
        fetchEmployee();
    };

    const handleAddEmployee = () => {
        setIsEditMode(false);
        clearFields();
        setadd_employee_modal(true);
    };

    const handleViewDetails = (rowData) => {
        setIsEditMode(true);
        setSelectedEmployee(rowData);
        populateFields(rowData);
        setadd_employee_modal(true);
    };

    const clearFields = () => {
        setName('');
        setEmail('');
        setPassword('');
        setDepartment('');
        setDesignation('');
        setContact('');
    };

    const populateFields = (rowData) => {
        setName(rowData.Name);
        setEmail(rowData.Email);
        setDepartment(rowData.Department);
        setDesignation(rowData.Designation);
        setContact(rowData.Contact);
    };

    useEffect(() => {
        fetchRole();
        fetchEmployee();
    }, []);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 50;
    const totalPages = Math.ceil(data.length / itemsPerPage);
    const paginatedData = data.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container className="mt-4" fluid={true} style={{ backgroundColor: "white", padding: "20px" }}>
                    <Row>
                        <Col sm={6}>
                            <h5>Employee</h5>
                        </Col>
                        <Col sm={6} className="text-end">
                            <Button color="primary" onClick={handleAddEmployee}>
                                Add Employee
                            </Button>
                        </Col>
                    </Row>

                    <Modal isOpen={add_employee_modal} toggle={() => setadd_employee_modal(!add_employee_modal)} size="lg">
                        <ModalHeader toggle={() => setadd_employee_modal(!add_employee_modal)}>
                            {isEditMode ? "Update Employee" : "Add Employee"}
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Employee Name</Label>
                                        <Input
                                            type="text"
                                            placeholder="Type here..."
                                            onChange={(e) => setName(e.target.value)}
                                            value={Name}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Designation</Label>
                                        <Input
                                            type="text"
                                            placeholder="Type here..."
                                            onChange={(e) => setDesignation(e.target.value)}
                                            value={Designation}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Contact</Label>
                                        <Input
                                            type="text"
                                            placeholder="Type here..."
                                            onChange={(e) => setContact(e.target.value)}
                                            value={Contact}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Department</Label>
                                        <Input
                                            type="text"
                                            placeholder="Type here..."
                                            onChange={(e) => setDepartment(e.target.value)}
                                            value={Department}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Email</Label>
                                        <Input
                                            type="email"
                                            placeholder="Type here..."
                                            onChange={(e) => setEmail(e.target.value)}
                                            value={Email}
                                        />
                                    </FormGroup>
                                </Col>
                                {!isEditMode && (
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>Password</Label>
                                            <Input
                                                type="password"
                                                placeholder="Type here..."
                                                onChange={(e) => setPassword(e.target.value)}
                                                value={Password}
                                            />
                                        </FormGroup>
                                    </Col>
                                )}
                                <Col sm={12} className="text-end">
                                    <Button color="primary" onClick={isEditMode ? editEmployee : addEmployee}>
                                        {isEditMode ? "Save" : "Submit"}
                                    </Button>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>

                    <Row className="mt-4">
                        <Col sm={3}>
                            <div className="table-subheader">
                                <span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search here..."
                                        onChange={(e) => {
                                            setSearchText(e.target.value)
                                        }}
                                    />
                                </span>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-2">
                        <Col>
                            <DataTable
                                columns={columns}
                                data={data}
                                pagination
                                paginationPerPage={30}
                                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                                highlightOnHover
                                striped
                                responsive
                                // subHeader
                                // subHeaderAlign="left"
                                // subHeaderComponent={
                                //     <div className="table-subheader">
                                //         <span>
                                //             <input
                                //                 type="text"
                                //                 className="form-control"
                                //                 placeholder="Search here..."
                                //                 onChange={(e) => {
                                //                     setSearchText(e.target.value)
                                //                 }}
                                //             />
                                //         </span>
                                //     </div>
                                // }
                            />
                        </Col>
                    </Row>

                    <Row>

                        <Pagination style={{ float: "right" }}>
                            <PaginationItem disabled={currentPage == 1}>
                                <PaginationLink previous onClick={handlePrevPage} />
                            </PaginationItem>

                            {[...Array(totalPages)].map((_, index) => (
                                <PaginationItem active={currentPage == index + 1} key={index}>
                                    <PaginationLink onClick={() => setCurrentPage(index + 1)}>
                                        {index + 1}
                                    </PaginationLink>
                                </PaginationItem>
                            ))}

                            <PaginationItem disabled={currentPage == totalPages}>
                                <PaginationLink next onClick={handleNextPage} />
                            </PaginationItem>
                        </Pagination>

                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};



export default Employee;
