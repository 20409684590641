import React, { useEffect, useMemo, useState } from "react";
import {
  Table, Row, Col, Container, Button, Modal, ModalBody, FormGroup,
  Label, Input, Pagination, PaginationItem, PaginationLink, ModalHeader,
  Tooltip
} from "reactstrap";
import { useNavigate } from 'react-router-dom';
import { getLoggedInUserRole } from "helpers/fakebackend_helper";
import { get, post, postForm } from "helpers/api_helper";
import moment from 'moment';
import DataTable from "react-data-table-component";

const formatDateTime = (isoDate) => {
  return moment(isoDate).format('DD/MM/YYYY HH:mm:ss');
};

const Projects = () => {
  document.title = "Projects";

  const getValue = (row, accessor) => {
    if (typeof accessor === "function") {
      return accessor(row);
    }
    return row[accessor] || "";
  };

  const [data, setData] = useState([]);
  const [role, setRole] = useState([]);
  const [files, setFiles] = useState([]);
  const [searchText, setSearchText] = useState("");

  const getUsers = async () => {
    const data = await get(`user/find`);
    setRole(data);
  }

  const getProject = async () => {
    const data = await get(`project/find`);
    setData(data);
  }

  useEffect(() => {
    getProject();
    getUsers();
  }, [])

  const [Name, setName] = useState("")
  const [Type, setType] = useState("")
  const [Description, setDescription] = useState("")
  const [Amount, setAmount] = useState(0)
  const [EndDate, setEndDate] = useState()
  const [PsID, setPsID] = useState()
  const [EoID, setEoID] = useState()
  const [hodID, sethodID] = useState()

  const [HeadCost, setHeadCost] = useState('');

  const headCostList = [
    "MoHAIT - 2300123234",
    "MoHAFIN - 23001236578",
    "MoHACON - 2300123455",
    "MoHAMAR - 2300124356"
  ]

  const columns = useMemo(
    () => [
      {
        name: 'Project ID',
        selector: (row) => `P${row.ProjectID}`,
        sortable: true,
        enableSorting: true,
      },
      {
        name: 'Project Name',
        selector: (row) => row.Name,
        sortable: true,
        enableSorting: true,
      },
      {
        name: 'Create Date',
        selector: (row) => formatDateTime(row.createdDate),
        sortable: true,
        enableSorting: true,
      },
      {
        name: 'End Date',
        selector: (row) => formatDateTime(row.EndDate),
        sortable: true,
        enableSorting: true,
      },
      {
        name: 'Head Cost',
        selector: row => row.HeadCost,
        sortable: true,
        enableSorting: true,
      },
      {
        name: 'Status',
        selector: (row) => row.Status,
        sortable: true,
        enableSorting: true,
      },
      {
        name: 'Amount',
        selector: (row) => row.Amount,
        sortable: true,
        enableSorting: true,
      },
      {
        name: 'Assigned PS',
        selector: (row) => row.ps ? row?.ps?.Name : "",
        sortable: true,
        enableSorting: true,
      },
      {
        name: "Action",
        cell: (row) => (
          <Button
            color="primary"
            size="sm"
            onClick={() => handleViewDetails(row)}
          >
            View Details
          </Button>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ],
    []
  );

  // const data = [
  //   { projectid: "P123", creationdate: "24-10-05 9:00", enddate: "24-12-05 5:00", projecttype: "Construction", status: "In Progress", amount: "$1990", assignedps: "Sarah Connor" },
  //   { projectid: "P123", creationdate: "24-10-05 9:00", enddate: "24-12-05 5:00", projecttype: "Construction", status: "In Progress", amount: "$129990", assignedps: "Sarah Connor" },
  //   { projectid: "P123", creationdate: "24-10-05 9:00", enddate: "24-12-05 5:00", projecttype: "Construction", status: "In Progress", amount: "$45632", assignedps: "Emily Davis" },
  //   { projectid: "P123", creationdate: "24-10-05 9:00", enddate: "24-12-05 5:00", projecttype: "Construction", status: "In Progress", amount: "$9375", assignedps: "James Smith" },
  //   { projectid: "P123", creationdate: "24-10-05 9:00", enddate: "24-12-05 5:00", projecttype: "Construction", status: "In Progress", amount: "$9375", assignedps: "James Smith" },
  //   { projectid: "P123", creationdate: "24-10-05 9:00", enddate: "24-12-05 5:00", projecttype: "Construction", status: "In Progress", amount: "$450", assignedps: "Michael Clarke" },
  //   { projectid: "P123", creationdate: "24-10-05 9:00", enddate: "24-12-05 5:00", projecttype: "Construction", status: "In Progress", amount: "$129990", assignedps: "Sarah Connor" },
  //   { projectid: "P123", creationdate: "24-10-05 9:00", enddate: "24-12-05 5:00", projecttype: "Construction", status: "In Progress", amount: "$45632", assignedps: "Emily Davis" },
  //   { projectid: "P123", creationdate: "24-10-05 9:00", enddate: "24-12-05 5:00", projecttype: "Construction", status: "In Progress", amount: "$9375", assignedps: "James Smith" },
  // ];

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const paginatedData = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const navigate = useNavigate();

  const handleViewDetails = (rowData) => {
    console.log("View details for:", rowData);
    navigate(`/project-detail/${rowData.ProjectID}`);
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const [add_project_modal, setadd_project_modal] = useState(false);

  const addProject = async () => {
    const mydata = new FormData();
    mydata.append('Name', Name);
    mydata.append('Type', Type);
    mydata.append('HeadCost', HeadCost)
    mydata.append('Description', Description);
    mydata.append('Amount', Amount);
    mydata.append('EndDate', EndDate);
    mydata.append('PsID', PsID);
    mydata.append('EoID', EoID);
    mydata.append('hodID', hodID);

    for (let i = 0; i < files.length; i++) {
      mydata.append('mediaFiles', files[i]);
    }
    // mydata.append('files',files);

    // const mydata = {
    //   Name,
    //   HeadCost,
    //   Type,
    //   Description,
    //   Amount: parseFloat(Amount),
    //   EndDate,
    //   PsID: PsID || 1,
    //   EoID: EoID || 1,
    //   hodID: hodID || 1
    // }

    console.log(mydata);

    // for (let pair of mydata.entries()) {
    //   console.log(pair[0] + ': ' + pair[1]);
    // }

    const res = await postForm(`project/create`, mydata);
    setadd_project_modal(false);
    getProject();
  }

  const handleFileChange = (e) => {
    setFiles(e.target.files);
  };

  const add_project = () => {
    setadd_project_modal(!add_project_modal);
    removeBodyCss();
  };

  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    setUserRole(getLoggedInUserRole());
  }, []);

  const filteredData = searchText.trim() === ""
    ? data // If searchText is empty, return all data without filtering
    : data.filter(
      (item) =>
        item?.Name?.toLowerCase().includes(searchText.toLowerCase()) ||
        item?.HeadCost?.toString().includes(searchText) ||
        item?.Status?.toLowerCase().includes(searchText.toLowerCase()) ||
        item?.Amount?.toString().includes(searchText) ||
        (item.ps?.Name || "").toLowerCase().includes(searchText.toLowerCase())
    );

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container className="mt-4" fluid={true} style={{ backgroundColor: "white", padding: "20px" }}>
          <Row>
            <Col sm={6}><h5>Projects<i id="pageHeading" style={{ marginLeft: "5px", fontSize: "0.8em" }} className="fa fa-info-circle"></i>
            </h5>

              <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                target="pageHeading"
                toggle={toggleTooltip}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras dignissim rhoncus turpis.
              </Tooltip>

            </Col>
            {userRole == 'Finance Officer' && <Col sm={6} className="text-end"><Button color="primary" onClick={add_project}> Add Project </Button></Col>}

            <Modal isOpen={add_project_modal} toggle={add_project} size="lg">
              <ModalHeader className="mt-0" toggle={add_project}>Add Project</ModalHeader>
              <ModalBody>
                <Row>
                  <Col sm={6}>
                    <FormGroup className="d-flex align-items-center text-nowrap">
                      <Label for="" className="me-4 w-50">Project Name</Label>
                      <Input
                        type="text"
                        id=""
                        placeholder="Project Name"
                        onChange={(e) => setName(e.target.value)}
                        value={Name}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="d-flex align-items-center text-nowrap">
                      <Label for="" className="me-4 w-50">Select Head Cost</Label>
                      <Input
                        type="select"
                        id=""
                        placeholder="Head Cost"
                        onChange={(e) => setHeadCost(e.target.value)}
                        value={HeadCost}
                      >
                        <option>Select</option>
                        {headCostList.map((hostCost) => (
                          <option value={hostCost}>{hostCost}</option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="d-flex align-items-center text-nowrap">
                      <Label for="endDate" className="me-4 w-50">End Date</Label>
                      <input
                        className="form-control"
                        type="date"
                        defaultValue={"6/12/2024"}
                        id="endDate"
                        onChange={(e) => setEndDate(e.target.value)}
                        value={EndDate}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="d-flex align-items-center text-nowrap">
                      <Label for="eo" className="me-4 w-50">Assigned EO</Label>
                      <Input
                        type="select"
                        id="eo"
                        placeholder="Assigned EO"
                        onChange={(e) => setEoID(e.target.value)}
                        value={EoID}>
                        <option>Select</option>
                        {role?.filter(x => x.role.Name == 'Examination Officer')?.map((user) => (
                          <option value={user.UserID}>{user.Name}</option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="d-flex align-items-center text-nowrap">
                      <Label for="hod" className="me-4 w-50">Assigned HOD</Label>
                      <Input
                        type="select"
                        id="hod"
                        onChange={(e) => sethodID(e.target.value)}
                        value={hodID}
                        placeholder="Assigned HOD">
                        <option>Select</option>
                        {role?.filter(x => x.role.Name == 'HOD')?.map((user) => (
                          <option value={user.UserID}>{user.Name}</option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="d-flex align-items-center text-nowrap">
                      <Label for="ps" className="me-4 w-50">Assigned PS</Label>
                      <Input
                        type="select"
                        id="ps"
                        onChange={(e) => setPsID(e.target.value)}
                        value={PsID}
                        placeholder="Assigned PS">
                        <option>Select</option>
                        {role?.filter(x => x.role.Name == 'Permanent Secretary')?.map((user) => (
                          <option value={user.UserID}>{user.Name}</option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="d-flex align-items-center text-nowrap">
                      <Label for="amount" className="me-4 w-50">Project Amount</Label>
                      <Input
                        type="text"
                        id="amount"
                        placeholder="Project Amount"
                        onChange={(e) => setAmount(e.target.value)}
                        value={Amount}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={12}>
                    <FormGroup className="d-flex align-items-center text-nowrap">
                      <label for="" class="me-3 form-label">Project Description</label>
                      <Input
                        type="textarea"
                        id=""
                        placeholder="Project Description"
                        className="h-25"
                        onChange={(e) => setDescription(e.target.value)}
                        value={Description}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={12}>
                    <FormGroup className="d-flex align-items-center text-nowrap">
                      <label for="" class="me-5 form-label">Add Document</label>
                      <Input
                        type="file"
                        multiple
                        id=""
                        placeholder="Project Description"
                        className="h-25"
                        onChange={handleFileChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={12} className="text-end">
                    <button className="btn btn-primary mt-5" onClick={addProject}>Submit</button>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>

          </Row>

          <Row className="mt-4">
            <Col sm={3}>
              <div className="table-subheader">
                <span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here..."
                    onChange={(e) => {
                      setSearchText(e.target.value)
                    }}
                  />
                </span>
              </div>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={30}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                highlightOnHover
                striped
                //className="table table-bordered"
                responsive
                // subHeader
                // subHeaderAlign="left"
                // subHeaderComponent={
                //   <div className="table-subheader">
                //     <span>
                //       <input
                //         type="text"
                //         className="form-control"
                //         placeholder="Search here..."
                //         onChange={(e) => {
                //           setSearchText(e.target.value)
                //         }}
                //       />
                //     </span>
                //   </div>
                // }
              // customStyles={{
              //   cells: {
              //     style: {
              //       borderRight: '1px solid #dee2e6', // Adds vertical borders between columns
              //     },
              //   },
              // }}
              />
            </Col>
          </Row>
          <Row>

            <Pagination style={{ float: "right" }}>
              <PaginationItem disabled={currentPage == 1}>
                <PaginationLink previous onClick={handlePrevPage} />
              </PaginationItem>

              {[...Array(totalPages)].map((_, index) => (
                <PaginationItem active={currentPage == index + 1} key={index}>
                  <PaginationLink onClick={() => setCurrentPage(index + 1)}>
                    {index + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}

              <PaginationItem disabled={currentPage == totalPages}>
                <PaginationLink next onClick={handleNextPage} />
              </PaginationItem>
            </Pagination>

          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Projects;
