import React, {useEffect, useState} from 'react';
import { get, post, put } from "../../../../src/helpers/api_helper";
import { Row, Col, Label, Input, Button, FormGroup, Form } from "reactstrap";

const ApproveVisitorForm = ({toggle, onSubmit, visitorData}) => {

  const [ExternalID, setExternalID] = useState("");
  const [VisitorID, setVisitorID] = useState("");

  const [searchedVisitorData, setsearchedVisitorData] = useState("");

  const [approvalFormData, setapprovalFormData] = useState({
    ApprovalType:     "Manual",
    VisitPurpose:     "Project Meeting",
  });

  const [checkinFormData, setCheckinFormData] = useState({
    CheckinAuthority:   "Help Desk Officer 1",
    CheckinDate:        new Date().toLocaleDateString('en-US'), // Explicit locale for mac support
    CheckinTime:        new Date().toLocaleTimeString('en-US'), // Explicit locale for mac support
  });

  const handleApprovalChange = (e) => {
    const { id, value } = e.target;
    setapprovalFormData((prevData) => ({
        ...prevData,
        [id]: value,
    }));
  };

  const handleCheckinChange = (e) => {
    const { id, value } = e.target;
    setCheckinFormData((prevData) => ({
        ...prevData,
        [id]: value,
    }));
  };



  const   clickCheckin = () => {
    console.log('CheckIn clicked!', { BuildingAccess, ApprovalAuthority, VisitPurpose });

    if(!formValidation()){
      return;
    }

    approvalFormData.VisitorID = searchedVisitorData?.VisitorID;
    checkinFormData.VisitorID = searchedVisitorData?.VisitorID;
    
    let payload = {
        approvalDetails:    approvalFormData,
        checkinDetails:     checkinFormData,
        VisitorID:          searchedVisitorData?.VisitorID,
    }

    onSubmit(payload);
    // toggle();
  };



  const findVisitor = async () => {
    try {
      let data = await get("visitors/find");

      if (ExternalID && ExternalID.length > 0) {
        data = data.filter(item => item.ExternalID == ExternalID);
      }

      if (VisitorID && VisitorID.length > 0) {
        data = data.filter(item => item.VisitorID.toString().padStart(5, '0') == VisitorID.toString());
      }

      setsearchedVisitorData(data[0]);
    } 
    catch (error) {
      console.error("Error fetching visitor data:", error);
    }
  };

  useEffect(() => {
    console.log("Get Visitor Details");
    setsearchedVisitorData(visitorData);
  }, []);


  const formValidation = () => {
    if(!approvalFormData.ApprovalAuthority){
      alert('Please Enter Approval Authority');
      return false;
    }
    else if(!approvalFormData.BuildingAccess){
      alert('Please Enter Building Name');
      return false;
    }
    else if(!checkinFormData.VisitorPassNumber){
      alert('Please Enter Visitor Pass Number');
      return false;
    }

    return true;
  }

  return (
    <>
        {/* <h5>Check-In Visitor</h5>

        <hr /> */}

        <Row className="mb-4 mt-4" hidden={visitorData}>
            <Col sm={3}>
                <FormGroup>
                  <Label for="VisitorID">Visitor ID</Label>
                  <Input
                    type="text"
                    id="VisitorID"
                    placeholder="Enter Visitor ID"
                    value={VisitorID}
                    onChange={(e) => setVisitorID(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <FormGroup>
                  <Label for="ExternalID">ID Number</Label>
                  <Input
                    type="text"
                    id="ExternalID"
                    placeholder="NIC # / Passport #/ License #"
                    value={ExternalID}
                    onChange={(e) => setExternalID(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col sm={2}>
                <Button color="primary" style={{ marginTop: "30px" }} onClick={findVisitor}>
                  Search
                </Button>
              </Col>

              <hr></hr>
            </Row>
          

        <h5>Visitor Details</h5>

        <Row>
            <Col sm={3}>
                <strong>Visitor ID:</strong> {searchedVisitorData?.VisitorID?.toString().padStart(5, '0') || ""}
            </Col>

            <Col sm={3}>
                <strong>Visitor Name:</strong> {searchedVisitorData?.VisitorName || ""}
            </Col>

            <Col sm={3}>
                <strong>ID Number:</strong> {searchedVisitorData?.ExternalID || ""}
            </Col>

            <Col sm={3}>
                <strong>ID Type:</strong> {searchedVisitorData?.ExternalIDType || ""}
            </Col>

        </Row>


        <hr/>

        <h5 style={{marginTop:"20px"}}>Approval Details</h5>
        <Form>
          <Col>
              <Row className="mb-4">
                  <Col md={4}>
                      <FormGroup>
                          <Row>
                              <Label for="ApprovalAuthority">Approval Authority</Label>

                              <Input type="text"
                                    id="ApprovalAuthority"
                                    placeholder="Enter Approval Authority"
                                    value={approvalFormData.ApprovalAuthority}
                                    onChange={handleApprovalChange}
                                    required>
                              </Input>
                          </Row>
                      </FormGroup>
                  </Col>


                  <Col md={4}>
                      <FormGroup>
                          <Label for="ApprovalType">Approval Type</Label>
                          <Input type="select"
                                  id="ApprovalType"
                                  value={approvalFormData.ApprovalType}
                                  onChange={handleApprovalChange}>
                              <option>Manual</option>
                              <option>Call</option>
                              <option>Email</option>
                          </Input>
                      </FormGroup>
                  </Col>

              </Row>
          </Col>

          <hr/>

          <h5 style={{marginTop:"20px"}}>Check-In Details</h5>


          <Col>
              <Row className="mb-4">
                  <Col md={3}>
                      <FormGroup>
                          <Row>
                              <Label for="CheckinAuthority">Check-In Officer</Label>

                              <Input type="select"
                                  id="CheckinAuthority"
                                  value={checkinFormData.CheckinAuthority}
                                  onChange={handleCheckinChange}
                                  required>
                              <option>Help Desk Officer 1</option>
                              <option>Help Desk Officer 2</option>
                          </Input>

                          </Row>
                      </FormGroup>
                  </Col>

                  <Col md={3}>
                      <FormGroup>
                          <Label for="VisitorPassNumber">Visitor Pass Number</Label>
                          <Input type="text"
                                  id="VisitorPassNumber"
                                  placeholder="Enter Pass Number"
                                  value={checkinFormData.VisitorPassNumber}
                                  onChange={handleCheckinChange}>
                          </Input>
                      </FormGroup>
                  </Col>


                  <Col md={3}>
                      <FormGroup>
                          <Label for="BuildingAccess">Building Access</Label>
                          <Input 
                              type="text"
                              id="BuildingAccess"
                              placeholder="Enter Building Name"
                              value={approvalFormData.BuildingAccess}
                              onChange={handleApprovalChange}
                              required
                          />
                      </FormGroup>
                  </Col>

                  <Col sm={3}>
                      <Label for="purpose">Purpose of Visit</Label>
                      <Input type="select"
                          id="VisitPurpose"
                          value={approvalFormData.VisitPurpose}
                          onChange={handleApprovalChange}
                          required>
                              <option>Project Meeting</option>
                              <option>Interview</option>
                              <option>Official Meeting</option>
                              <option>Personal Visit</option>
                      </Input>
                  </Col>
              </Row>
              <Button color="primary" onClick={clickCheckin} style={{float:"right"}} disabled={!searchedVisitorData?.VisitorID} >
                  Check-In
              </Button>
          </Col>
        </Form>
    </>
  );
};

export default ApproveVisitorForm;
