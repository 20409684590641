import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {Table, Row, Col, Container, Button, Modal, ModalBody, FormGroup, 
        Label, Input, Pagination, PaginationItem,PaginationLink,
        ModalHeader} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { get, post, put } from "../../../src/helpers/api_helper";
import AddVisitor from "./BuildingSecuritySetup/AddVisitorForm";

const VisitorManagement = () => {
  const navigate = useNavigate();

  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const [visitorData, setVisitorData] = useState([]);
  const [selectedVisitorData, setSelectedVisitorData] = useState([]);

  const [VisitorName, setVisitorName] = useState("");
  const [VisitorIDNumber, setVisitorIDNumber] = useState("");

  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const itemsPerPage = 50; // Number of items per page

  const toggleModal = () => setModal(!modal);
  const toggleEditModal = () => setEditModal(!editModal);
  
  const handleFormSubmit = async (formData) => {
    try {

      if(!formData.VisitorID){
        let addVisitor = await post("visitors/create", formData);
        alert("Visitor added successfully!");
        setModal(false);
        navigate(`/visitor-details/${addVisitor.VisitorID}`, { state: { visitor: addVisitor } });
      }
      else{
        let updateVisitor = await put("visitors/update/" + formData.VisitorID, formData);
        alert("Visitor updated successfully!");
        setEditModal(false);
      }

      fetchVisitors();
    } 
    catch (error) {
      console.error("Error adding visitor:", error);
      alert("Failed to add visitor.");
    }
  };


  const findVisitor = async () => {
    try {
      let data = await get("visitors/find");

      if (VisitorName && VisitorName.length > 0) {
        data = data.filter(item => item.VisitorName.toLowerCase().includes(VisitorName.toLowerCase()));
      }

      if (VisitorIDNumber && VisitorIDNumber.length > 0) {
        data = data.filter(item => item.ExternalID.toString().includes(VisitorIDNumber.toString()));
      }

      setVisitorData(data);
    } 
    catch (error) {
      console.error("Error fetching visitor data:", error);
    }
  };

  const fetchVisitors = async () => {
    try {
      const data = await get("visitors/find");
      setVisitorData(data);
    } catch (error) {
      console.error("Error fetching visitor data:", error);
    }
  };

  const viewDetails = async (visitor) => {
    console.log("View Details")
    navigate(`/visitor-details/${visitor.VisitorID}`, { state: { visitor } });
  };

  const editDetails = async (visitor) => {
    console.log("Edit Details")
    setSelectedVisitorData(visitor);
    console.log(selectedVisitorData)
    toggleEditModal();
   };

  useEffect(() => {
    fetchVisitors();
  }, []);

  const columns = useMemo(
    () => [
      { 
        header:   "Visitor ID", 
        accessor: (row) => row.VisitorID.toString().padStart(5, '0'),
      },
      { 
        header:   "Visitor Name", 
        accessor: "VisitorName",
      },
      { 
        header:   "Visitor Address", 
        accessor: "VisitorAddress",
      },
      { 
        header:   "ID Number", 
        accessor: "ExternalID",
      },
      { 
        header:   "ID Type", 
        accessor: "ExternalIDType",
      },
      // { header: "Approval Authority", accessor: "ApprovalAuthority" },
      // { header: "Approval Type", accessor: "ApprovalType" },
      // { header: "Building Access", accessor: "BuildingAccess" },
      // { header: "Purpose of Visit", accessor: "VisitPurpose" },
      { 
        header: "Actions", 
        accessor: "actions" 
      },
    ],
    []
  );

  // Pagination logic
  const totalPages = Math.ceil(visitorData.length / itemsPerPage);
  const paginatedData = visitorData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };


  const getValue = (row, accessor) => {
    if (typeof accessor == "function") {
      return accessor(row);
    }
    return row[accessor] || "";
  };

  document.title = "Visitor Management | Building Security";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row className="align-items-center">
            <Col sm={6}>
              <Breadcrumbs title="Building Security" breadcrumbItem="Visitor Management" />
            </Col>
            <Col sm={6}>
              <div className="float-end d-none d-md-block">
                <Button
                  color="primary"
                  onClick={toggleModal}
                  className="btn btn-primary waves-effect waves-light"
                >
                  <i className="fa fa-plus" style={{ marginRight: "10px" }}></i>
                  Add Visitor
                </Button>{" "}
              </div>
            </Col>
          </Row>

          {/* Modal */}
          <Modal isOpen={modal} toggle={toggleModal} size="lg">
            <ModalHeader className="mt-0" toggle={toggleModal}>Add Visitor</ModalHeader>
            <ModalBody>
              <AddVisitor onSubmit={handleFormSubmit} toggle={toggleModal} visitorData={null} />
            </ModalBody>
          </Modal>


          <Modal isOpen={editModal} toggle={toggleEditModal} size="lg">
            <ModalBody>
              <AddVisitor onSubmit={handleFormSubmit} toggle={toggleEditModal} visitorData={selectedVisitorData} />
            </ModalBody>
          </Modal>


          {/* Search and Table */}
          <Container fluid={true} style={{ backgroundColor: "white", padding: "20px" }}>
            <h5>Visitors</h5>
            <p>View, Search and Manage Visitors Entering The MoHA Building</p>

            <Row className="mb-4 mt-4">
              <Col sm={3}>
                <FormGroup className="d-flex align-items-center text-nowrap">
                  <Label for="VisitorName" className="me-4">Visitor Name</Label>
                  <Input
                    type="text"
                    id="VisitorName"
                    placeholder="Enter Visitor Name"
                    value={VisitorName}
                    onChange={(e) => setVisitorName(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col sm={3}>
                <FormGroup className="d-flex align-items-center text-nowrap">
                  <Label for="VisitorIDNumber" className="me-4">ID Number</Label>
                  <Input
                    type="text"
                    id="VisitorIDNumber"
                    placeholder="Enter ID Number"
                    value={VisitorIDNumber}
                    onChange={(e) => setVisitorIDNumber(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col sm={2}>
                <Button color="primary" onClick={findVisitor}>
                  Search
                </Button>
              </Col>
            </Row>


            <Table bordered responsive>
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th key={index}>{column.header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {columns.map((column, colIndex) => (
                      <td key={colIndex}>
                        {column.accessor == "actions" ? (
                          <div>
                          <button className="btn btn-primary btn-sm" onClick={() => viewDetails(row)} style={{ marginRight: "5px" }}>
                            View Details
                          </button>

                            {/* <button className="btn btn-primary btn-sm" onClick={() => editDetails(row)} style={{ marginRight: "5px" }}>
                              Edit
                            </button> */}
                          </div>
                        ) : (
                          getValue(row, column.accessor)
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>

            {/* Pagination Controls */}
            <Row>

              <Pagination style={{float:"right"}}>
                <PaginationItem disabled={currentPage == 1}>
                  <PaginationLink previous onClick={handlePrevPage} />
                </PaginationItem>

                {[...Array(totalPages)].map((_, index) => (
                  <PaginationItem active={currentPage == index + 1} key={index}>
                    <PaginationLink onClick={() => setCurrentPage(index + 1)}>
                      {index + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}

                <PaginationItem disabled={currentPage == totalPages}>
                  <PaginationLink next onClick={handleNextPage} />
                </PaginationItem>
              </Pagination>

            </Row>
          </Container>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default VisitorManagement;
