import React, { useEffect, useMemo, useState } from "react";
import {
    Table, Row, Col, Container, Button, Modal, ModalBody, FormGroup,
    Label, Input, Pagination, PaginationItem, PaginationLink
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { getLoggedInUser, getLoggedInUserRole } from "helpers/fakebackend_helper";
import { useNavigate, useParams } from "react-router-dom";
import { get, post, put, putForm } from "helpers/api_helper";
import moment from 'moment';

import './project.css';

const formatDateTime = (isoDate) => {
    return moment(isoDate).format('DD/MM/YYYY HH:mm:ss');
};

const ProjectDetail = () => {
    document.title = "Project";
    const navigate = useNavigate();
    const [userRole, setUserRole] = useState('');
    const [project, setProject] = useState({});
    const { id } = useParams();

    const [Name, setName] = useState('');
    const [Type, setType] = useState('');
    const [Description, setDescription] = useState('');
    const [Amount, setAmount] = useState();
    const [HeadCost, setHeadCost] = useState('');
    const [ApprovalLogs, setApprovalLogs] = useState([]);

    const [media, setMedia] = useState([]);

    const [eo, setEo] = useState([]);
    const [hod, setHod] = useState([]);
    const [ps, setPs] = useState([]);
    const [employee, setEmployee] = useState([]);
    const [vendor, setVendor] = useState([]);

    const [EoID, setEoID] = useState('');
    const [hodID, setHodID] = useState('');
    const [PsID, setPsID] = useState('');
    const [employeeId, setEmployeeId] = useState();
    const [vendorId, setVendorId] = useState();

    const [employeeList, setEmployeeList] = useState([]);
    const [vendorList, setVendorList] = useState([]);

    const [deletedFiles, setDeletedFiles] = useState([]);
    const [files, setFiles] = useState([]);

    const handleFileChange = (e) => {
        setFiles(e.target.files);
    };

    const getProjectById = async () => {
        const res = await get(`project/find/${id}`);
        setProject(res);
        setName(res.Name);
        setType(res.Type);
        setDescription(res.Description);
        setAmount(res.Amount);
        setHeadCost(res.HeadCost);
        setEoID(res.EoID);
        setHodID(res.hodID);
        setPsID(res.PsID);
        setEmployeeList(res.employees);
        setVendorList(res.vendors);
        setApprovalLogs(res.ApprovalLogs);
        setMedia(res.media);
    }

    const getUsers = async () => {
        const res = await get('user/find');
        setEo(res.filter(x => x.role.Name == 'Examination Officer'));
        // setHod(res.filter(x=> x.role.Name == 'Head of Department'));
        setHod(res.filter(x => x.role.Name == 'HOD'));
        setPs(res.filter(x => x.role.Name == 'Permanent Secretary'));
        setEmployee(res.filter(x => x.role.Name == 'Employee'));
        setVendor(res.filter(x => x.role.Name == 'Vendor'));
    }

    const addEmployee = async () => {

        if (!employeeId) {
            alert("Please select an employee before adding."); // Validation message
            return;
        }

        const isEmployeeAlreadyAdded = employeeList.some(e => e.UserID == employeeId);

        if (isEmployeeAlreadyAdded) {
            alert("This employee is already added to the list."); // Validation message
            setEmployeeId("");
            return;
        }

        try {
            await post('project/add-employee', { ProjectID: id, UserID: employeeId });
            getProjectById();
            setEmployeeId("");
            alert("Employee added successfully!"); // Feedback to user
        } catch (error) {
            console.error("Error adding vendor:", error);
            alert("Failed to add employee. Please try again."); // Error message
        } finally {
            // setIsLoading(false); // Stop loader
        }
    }

    const deleteEmployee = async (employeeId) => {
        try {
            await post('project/delete-employee', { ProjectID: id, UserID: employeeId });
            getProjectById();
            alert("Employee removed successfully!");
        } catch (error) {
            console.error("Error adding vendor:", error);
            alert("Failed to remove employee. Please try again.");
        } finally {
            // setIsLoading(false); // Stop loader
        }
    }

    const addVendor = async () => {
        // await post('project/add-vendor', { ProjectID: id, UserID: vendorId });
        // getProjectById();

        // setIsLoading(true); // Start loader

        if (!vendorId) {
            alert("Please select a vendor before adding."); // Validation message
            return;
        }

        const isVendorAlreadyAdded = vendorList.some(v => v.UserID == vendorId);

        if (isVendorAlreadyAdded) {
            alert("This vendor is already added to the list."); // Validation message
            setVendorId("");
            return;
        }

        try {
            await post('project/add-vendor', { ProjectID: id, UserID: vendorId });
            await getProjectById(); // Refresh project data
            setVendorId("");
            alert("Vendor added successfully!"); // Feedback to user
        } catch (error) {
            console.error("Error adding vendor:", error);
            alert("Failed to add vendor. Please try again."); // Error message
        } finally {
            // setIsLoading(false); // Stop loader
        }
    }

    const deleteVendor = async (vendorId) => {
        try {
            await post('project/delete-vendor', { ProjectID: id, UserID: vendorId });
            await getProjectById();
            alert("Vendor removed successfully!"); // Feedback to user
        } catch (error) {
            console.error("Error adding vendor:", error);
            alert("Failed to removed vendor. Please try again."); // Error message
        } finally {
            // setIsLoading(false); // Stop loader
        }
    }

    useEffect(() => {
        getUsers();
        getProjectById();
        setUserRole(getLoggedInUserRole());
    }, []);

    const headCostList = [
        "MoHAIT - 2300123234",
        "MoHAFIN - 23001236578",
        "MoHACON - 2300123455",
        "MoHAMAR - 2300124356"
    ]

    const handleApproval = async (status) => {
        const data = {
            ProjectID: id,
            Status: status,
            ApprovedBy: getLoggedInUser()?.id,
            Comments: status
        }

        await post(`project/create-approval`, data);
        getProjectById();
    }

    const handleSubmit = async () => {
        // const user = getLoggedInUser();
        // const data = {
        //     Name, Type, Description, Amount, HeadCost,
        //     PsID,
        //     EoID,
        //     hodID,
        //     deletedFiles
        // }

        const mydata = new FormData();
        mydata.append('Name', Name);
        mydata.append('Type', Type);
        mydata.append('HeadCost', HeadCost)
        mydata.append('Description', Description);
        mydata.append('Amount', Amount);
        mydata.append('PsID', PsID);
        mydata.append('EoID', EoID);
        mydata.append('hodID', hodID);
        mydata.append('deletedFiles', JSON.stringify(deletedFiles));

        for (let i = 0; i < files.length; i++) {
            mydata.append('mediaFiles', files[i]);
        }

        await putForm(`project/update/${id}`, mydata);
        navigate('/projects');
    }

    const handleRemoveMedia = (file) => {
        setDeletedFiles([...deletedFiles, file]);
        setMedia(media.filter(x => x.FilePath != file));
    }

    return (
        <React.Fragment>

            <div className="page-content">
                <Container className="mt-4" fluid={true} style={{ backgroundColor: "white", padding: "20px" }}>
                    <h5>Project Detail</h5>

                    <Row className="mb-4 mt-4">
                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="" className="me-1 w-50">Project Name</Label>
                                <Input type="text"
                                    placeholder="Project Name"
                                    id=""
                                    onChange={(e) => setName(e.target.value)}
                                    value={Name}
                                    required>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="" className="me-1 w-50">Select Head Cost</Label>
                                <Input type="select"
                                    placeholder="Select Head Cost"
                                    id=""
                                    onChange={(e) => setHeadCost(e.target.value)}
                                    value={HeadCost}
                                    required>
                                    <option>Select</option>
                                    {headCostList?.map((hostCost) => (
                                        <option value={hostCost}>{hostCost}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="" className="me-1 w-50">Project Amount</Label>
                                <Input type="number"
                                    placeholder="Project Amount"
                                    id=""
                                    onChange={(e) => setAmount(e.target.value)}
                                    value={Amount}
                                    required>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={12}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="" className="me-4">Project Description</Label>
                                <Input type="textarea"
                                    placeholder="Project Description"
                                    id=""
                                    className="h-20"
                                    onChange={(e) => setDescription(e.target.value)}
                                    value={Description}
                                    required>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="assignedEoID" className="me-1 w-50">Assigned EO</Label>
                                {eo && <Input type="select"
                                    placeholder="Assigned EO"
                                    id="assignedEoID"
                                    onChange={(e) => setEoID(e.target.value)}
                                    value={EoID}
                                    required>
                                    <option>Select</option>
                                    {eo?.map((userEO) => (
                                        <option value={userEO.UserID}>{userEO.Name}</option>
                                    ))}
                                </Input>}
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="assignedHOD" className="w-50">Assigned HOD</Label>
                                <Input type="select"
                                    placeholder="Assigned HOD"
                                    id="assignedHOD"
                                    onChange={(e) => setHodID(e.target.value)}
                                    value={hodID}
                                    required>
                                    <option>Select</option>
                                    {hod?.map((userHOD) => (
                                        <option value={userHOD.UserID}>{userHOD.Name}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="assignedPS" className="w-50">Assigned PS</Label>
                                {ps && <Input type="select"
                                    placeholder="Assigned PS"
                                    id="assignedPS"
                                    onChange={(e) => setPsID(e.target.value)}
                                    value={PsID}
                                    required>
                                    <option>Select</option>
                                    {ps?.map((userPS) => (
                                        <option value={userPS.UserID}>{userPS.Name}</option>
                                    ))}
                                </Input>}
                            </FormGroup>
                        </Col>
                    </Row>

                    {userRole == 'Finance Officer' &&
                        (
                            <Row className="mt-4">
                                <Col sm={12}>
                                    <FormGroup className="d-flex align-items-center justify-content-end text-nowrap">
                                        <button onClick={handleSubmit} className="btn btn-primary">Save</button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        )
                    }

                    <Row className="mb-4 mt-4">
                        <h5 className="mb-4">Employee</h5>
                        {userRole == 'Finance Officer' &&
                            (
                                <Row>
                                    <Col sm={4}>
                                        <FormGroup className="d-flex align-items-center text-nowrap">
                                            <Label for="headCostName" className="me-4">Employee</Label>
                                            <Input type="select"
                                                placeholder="Employee"
                                                id="headCostName"
                                                onChange={(e) => setEmployeeId(e.target.value)}
                                                required>
                                                <option>Select</option>
                                                {employee.map(emp => (
                                                    <option value={emp.UserID}>{emp.Name}</option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    </Col>

                                    <Col sm={4}>
                                        <FormGroup className="d-flex align-items-center text-nowrap">
                                            <button className="btn btn-primary" onClick={addEmployee}>Add</button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            )
                        }


                        {/* <Col sm={4}>
                                <FormGroup className="d-flex align-items-center text-nowrap">
                                    <Label for="amount" className="me-4">Employee Name</Label>
                                    <Input type="text"
                                        placeholder="Employee Name"
                                        id="Amount"
                                        required>
                                    </Input>
                                </FormGroup>
                            </Col> */}

                    </Row>

                    {employeeList.length > 0 && (
                        <Row>
                            <Container className="border rounded p-2 mb-3">
                                {employeeList && employeeList.map((employee) => (
                                    <Container className="border rounded p-3 mb-3">
                                        {/* <h5>{employee.employee.Name}</h5> */}


                                        <Row>
                                            <Col sm={6}>
                                                <h5>{employee?.employee?.Name ?? ""}</h5>
                                            </Col>

                                            <Col sm={6}>
                                                <FormGroup className="d-flex align-items-center justify-content-end text-nowrap">
                                                    <button className="btn btn-danger" onClick={() => deleteEmployee(employee?.employee?.UserID)}>Delete</button>
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        {/* <Row>
                                                <Col sm={3} className="pt-2">
                                                    <strong>Vendor Type: </strong>
                                                    <span>Supplier</span>
                                                </Col>
                                                <Col sm={3} className="pt-2">
                                                    <strong>Total Amount: </strong>
                                                    <span>$53600</span>
                                                </Col>
                                                <Col sm={3} className="pt-2">
                                                    <strong>Total Amount: </strong>
                                                    <span>$3525</span>
                                                </Col>
                                            </Row> */}
                                        <Row>
                                            <Col sm={3} className="pt-2">
                                                <strong>Employee ID: </strong>
                                                <span>{employee?.employee?.UserPrefixId ?? ""}</span>
                                            </Col>
                                            <Col sm={3} className="pt-2">
                                                <strong>Employee Name: </strong>
                                                <span>{employee?.employee?.Name ?? ""}</span>
                                            </Col>
                                            <Col sm={3} className="pt-2">
                                                <strong>Department: </strong>
                                                <span>{employee?.employee?.Department ?? ""}</span>
                                            </Col>
                                            <Col sm={3} className="pt-2">
                                                <strong>Designation: </strong>
                                                <span>{employee?.employee?.Designation ?? ""}</span>
                                            </Col>
                                        </Row>
                                        {employee?.employee?.paymentVouchersEmployee?.length > 0 &&
                                        <Table bordered responsive className="mt-3">
                                                <thead>
                                                    <tr>
                                                        {/* <th>Mile Stone</th>
                                                        <th>Payment Percentage</th> */}
                                                        <th>PV ID</th>
                                                        <th>Payment Amount</th>
                                                        <th>Payment Status</th>
                                                        {/* <th>PV ID</th> */}
                                                        <th>EO Approval</th>
                                                        <th>HOD Approval</th>
                                                        <th>PS Approval</th>
                                                        {/* {userRole == 'Permanent Secretary' && <th>Action</th>} */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {employee?.employee?.paymentVouchersEmployee?.map((employeeVoucher)=>(
                                                    <tr>
                                                        {/* <td>Milestone 1</td>
                                                        <td>25%</td> */}
                                                        <td>PV{employeeVoucher.ID}</td>
                                                        <td>${employeeVoucher.Amount}</td>
                                                        <td>{employeeVoucher.Status}</td>
                                                        {/* <td>PV{employeeVoucher.ID}</td> */}
                                                        <td>{employeeVoucher.EoStatus}</td>
                                                        <td>{employeeVoucher.HodStatus}</td>
                                                        <td>{employeeVoucher.PsStatus}</td>
                                                        {/* {userRole == 'Permanent Secretary' && <td><button class="btn btn-primary">Approve</button></td>} */}
                                                    </tr>))}
                                                </tbody>
                                            </Table>}
                                    </Container>))}
                            </Container>
                        </Row>
                    )}


                    <Row className="mb-4 mt-4">
                        <h5 className="mb-4">Vendor</h5>
                        {userRole == 'Finance Officer' &&
                            (
                                <Row>

                                    <Col sm={4}>
                                        <FormGroup className="d-flex align-items-center text-nowrap">
                                            <Label for="headCostName" className="me-4">Vendor</Label>
                                            <Input
                                                type="select"
                                                placeholder="Vendor"
                                                id="headCostName"
                                                value={vendorId}
                                                onChange={(e) => setVendorId(e.target.value)}
                                                required>
                                                <option>Select</option>
                                                {vendor.map(ven => (
                                                    <option value={ven.UserID}>{ven.Name}</option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    </Col>

                                    <Col sm={4}>
                                        <FormGroup className="d-flex align-items-center text-nowrap">
                                            <button className="btn btn-primary" onClick={addVendor}>Add</button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            )
                        }


                        {/* <Col sm={4}>
                                <FormGroup className="d-flex align-items-center text-nowrap">
                                    <Label for="amount" className="me-4">Employee Name</Label>
                                    <Input type="text"
                                        placeholder="Employee Name"
                                        id="Amount"
                                        required>
                                    </Input>
                                </FormGroup>
                            </Col> */}

                    </Row>

                    {vendorList.length > 0 && (
                        <Row>
                            <Container className="border rounded p-2 mb-3">
                                {vendorList && vendorList.map((vendor) => (
                                    <Container className="border rounded p-3 mb-3">
                                        {/* <h5>{vendor.vendor.Name}</h5> */}


                                        <Row>
                                            <Col sm={6}>
                                                <h5>{vendor?.vendor?.Name ?? ""}</h5>
                                            </Col>

                                            <Col sm={6}>
                                                <FormGroup className="d-flex align-items-center justify-content-end text-nowrap">
                                                    <button className="btn btn-danger" onClick={() => deleteVendor(vendor?.vendor?.UserID)}>Delete</button>
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        {/* <Row>
                                                <Col sm={3} className="pt-2">
                                                    <strong>Vendor Type: </strong>
                                                    <span>Supplier</span>
                                                </Col>
                                                <Col sm={3} className="pt-2">
                                                    <strong>Total Amount: </strong>
                                                    <span>$53600</span>
                                                </Col>
                                                <Col sm={3} className="pt-2">
                                                    <strong>Total Amount: </strong>
                                                    <span>$3525</span>
                                                </Col>
                                            </Row> */}
                                        <Row>
                                            <Col sm={3} className="pt-2">
                                                <strong>Vendor Address: </strong>
                                                <span>{vendor?.vendor?.Address ?? ""}</span>
                                            </Col>
                                            <Col sm={3} className="pt-2">
                                                <strong>Vendor Contact: </strong>
                                                <span>{vendor?.vendor?.ContactPerson ?? ""}</span>
                                            </Col>
                                            <Col sm={3} className="pt-2">
                                                <strong>Vendor Email: </strong>
                                                <span>{vendor?.vendor?.Email ?? ""}</span>
                                            </Col>
                                            <Col sm={3} className="pt-2">
                                                <strong>RTP ID: </strong>
                                                <span>{vendor?.vendor?.RTP_ID ?? ""}</span>
                                            </Col>
                                        </Row>
                                        {vendor?.vendor?.paymentVouchersVendor?.length > 0 &&
                                        <Table bordered responsive className="mt-3">
                                                <thead>
                                                    <tr>
                                                        {/* <th>Mile Stone</th>
                                                        <th>Payment Percentage</th> */}
                                                        <th>PV ID</th>
                                                        <th>Payment Amount</th>
                                                        <th>Payment Status</th>
                                                        {/* <th>PV ID</th> */}
                                                        <th>EO Approval</th>
                                                        <th>HOD Approval</th>
                                                        <th>PS Approval</th>
                                                        {/* {userRole == 'Permanent Secretary' && <th>Action</th>} */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {vendor?.vendor?.paymentVouchersVendor?.map((vendorVoucher)=>(
                                                    <tr>
                                                        {/* <td>Milestone 1</td>
                                                        <td>25%</td> */}
                                                        <td>PV{vendorVoucher.ID}</td>
                                                        <td>${vendorVoucher.Amount}</td>
                                                        <td>{vendorVoucher.Status}</td>
                                                        {/* <td>PV{vendorVoucher.ID}</td> */}
                                                        <td>{vendorVoucher.EoStatus}</td>
                                                        <td>{vendorVoucher.HodStatus}</td>
                                                        <td>{vendorVoucher.PsStatus}</td>
                                                        {/* {userRole == 'Permanent Secretary' && <td><button class="btn btn-primary">Approve</button></td>} */}
                                                    </tr>))}
                                                </tbody>
                                            </Table>}
                                    </Container>))}
                            </Container>
                        </Row>
                    )}

                    {/* <Row className="mb-4 mt-4">
                            <h5 className="mb-4">Employee Expense History</h5>
                            <Table bordered responsive>
                                <thead>
                                    <tr>
                                        <th>Employee Id</th>
                                        <th>Expense Type</th>
                                        <th>Payment Amount</th>
                                        <th>Payment Status</th>
                                        <th>EO Approval</th>
                                        <th>HOD Approval</th>
                                        <th>PS Approval</th>
                                        {userRole == 'Permanent Secretary' && <th>Action</th> }
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>EMP001</td>
                                        <td>Travel</td>
                                        <td>$1,200</td>
                                        <td>Pending</td>
                                        <td>Pending</td>
                                        <td>Pending</td>
                                        <td>Pending</td>
                                        {userRole == 'Permanent Secretary' &&  <td><button class="btn btn-primary">Approve</button>
                                        <button class="btn btn-danger m-1">Reject</button></td> }
                                    </tr>
                                </tbody>
                            </Table>
                        </Row> */}

                    <Row>
                        <h5>Approvals</h5>
                        <Col sm={6}>
                            <Table bordered responsive>
                                <tbody>
                                    <tr>
                                        <td>{project?.ps?.Name}</td>
                                        <td>{project?.Status}</td>
                                        {userRole == 'Permanent Secretary' && (project.Status == "Pending" || project.Status == "Rejected") &&
                                            <td>
                                                <button className="btn btn-primary" onClick={() => handleApproval("Approved")}>Approve</button>
                                                <button className="btn btn-danger mx-2" onClick={() => handleApproval("Rejected")}>Reject</button>
                                            </td>}
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                            <h5>Approval Logs</h5>
                            <Table bordered responsive>
                                <thead>
                                    <tr>
                                        {/* <th>Log ID</th> */}
                                        <th>Approver Name</th>
                                        <th>Status</th>
                                        <th>Date</th>
                                        <th>Comments</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ApprovalLogs && ApprovalLogs.map((approve) => (
                                        <tr>
                                            {/* <td>{approve.ApprovalLogID}</td> */}
                                            <td>{approve.approver.Name}</td>
                                            <td>{approve.ApprovalStatus}</td>
                                            <td>{formatDateTime(approve.ApprovalDate)}</td>
                                            <td>{approve.Comments}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12} style={{ marginBottom: "1rem" }}>
                            <h5>Media</h5>
                        </Col>

                        {userRole == 'Finance Officer' &&
                            (
                                <Row className="mt-4">
                                    <Col sm={10}>
                                        <FormGroup className="d-flex align-items-center text-nowrap">
                                            <label for="" class="me-5 form-label">Add Document</label>
                                            <Input
                                                type="file"
                                                multiple
                                                id=""
                                                placeholder="Project Description"
                                                className="h-25"
                                                onChange={handleFileChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={2}>
                                        <FormGroup className="d-flex align-items-center justify-content-end text-nowrap">
                                            <button onClick={handleSubmit} className="btn btn-primary">Save Document</button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            )
                        }

                        {/* <Col sm={12}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <label for="" class="me-5 form-label">Add Document</label>
                                <Input
                                    type="file"
                                    multiple
                                    id=""
                                    placeholder="Project Description"
                                    className="h-25"
                                    onChange={handleFileChange}
                                />
                            </FormGroup>
                        </Col> */}
                        <Col sm={12}>
                            <ul>
                                <Row>
                                    {media.map((file) => (
                                        <Col sm={3}>
                                            <li className="media-item">
                                                <span className="media-name">{file.FileName}</span>
                                                <a href={`https://moha.sawarionline.com/${file.FilePath}`} download> <i className="fa fa-download"></i> </a>
                                                <button onClick={() => handleRemoveMedia(file.FilePath)} className="cross-button"><i className="fa fa-trash"></i></button>
                                            </li>
                                        </Col>
                                    ))}
                                </Row>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ProjectDetail;
