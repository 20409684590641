import { del, get, post, put } from "helpers/api_helper";
import { getLoggedInUser, getLoggedInUserRole } from "helpers/fakebackend_helper";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    Table, Row, Col, Container, Button, Modal, ModalHeader, ModalBody, FormGroup,
    Label, Input, Pagination, PaginationItem, PaginationLink,
    Alert
} from "reactstrap";
import moment from 'moment';

const formatDateTime = (isoDate) => {
    return moment(isoDate).format('DD/MM/YYYY HH:mm:ss');
};

const UpdateYearlyExpense = () => {
    document.title = "Update Yearly Expense";
    const navigate = useNavigate();

    const { id } = useParams();
    const [psList, setPsList] = useState([]);
    const [data, setData] = useState({});

    const getPS = async () => {
        const res = await get('user/find-by-role-name/Permanent Secretary');
        setPsList(res);
    }

    const [Agency, setAgency] = useState('');
    const [ApprovalUserID, setApprovalUserID] = useState();
    const [TotalExpense, setTotalExpense] = useState(0);

    const [totalHeadCost, setTotalHeadCost] = useState(0);

    const [ApprovalLogs, setApprovalLogs] = useState([]);

    const [hcId, setHcId] = useState(0);
    const [hcName, setHcName] = useState("");
    const [hcAmount, setHcAmount] = useState(0.0);

    const [costError, setCostError] = useState("");

    const [Comment, setComment] = useState('');

    const [BudgetYear, setBudgetYear] = useState();

    const [headCosts, setHeadCosts] = useState([]);

    const headCostList = [
        "MoHAIT - 2300123234",
        "MoHAFIN - 23001236578",
        "MoHACON - 2300123455",
        "MoHAMAR - 2300124356"
    ];

    const currentYear = new Date().getFullYear();
    const years = [];

    for (let i = 0; i < 20; i++) {
        years.push(currentYear + i);
    }

    useEffect(() => {
        setTimeout(() => {
            setCostError("");
        }, 3000);
    }, [costError]);

    const getYearlyExpenseById = async () => {
        const getData = await get(`yearlyExpense/find/${id}`);
        console.log(getData);
        setData(getData);
        setAgency(getData?.Agency);
        setApprovalUserID(getData?.ApprovalUserID);
        setTotalExpense(getData?.TotalExpense);
        setBudgetYear(getData?.BudgetYear);
        setApprovalLogs(getData?.ApprovalLogs);
        setTotalHeadCost(getData.headCost.reduce((total, acc) => total + parseFloat(acc.Amount), 0));
    }

    useEffect(() => {
        getPS();
        getYearlyExpenseById();
    }, []);

    const handleSubmit = async () => {
        const user = getLoggedInUser();
        const data = {
            BudgetYear,
            Agency,
            TotalExpense,
            ApprovalUserID,
            ApprovalStatus: 'Pending',
            CreatedBy: user.id
        }

        await put(`yearlyExpense/update/${id}`, data);
        navigate('/yearly-expense');
    }

    const handleHeadCost = async () => {
        let tempCost = parseFloat(totalHeadCost) + parseFloat(hcAmount);
        if (tempCost > parseFloat(TotalExpense)) {
            setCostError(`Expense cannot exceed ${TotalExpense}`);
            return
        }

        const data = {
            ID: parseInt(hcId),
            Name: hcName,
            Amount: parseFloat(hcAmount),
            YearlyExpenseID: parseInt(id)
        }

        await post(`yearlyExpense/create-headcost`, data);
        getYearlyExpenseById();

        console.log(data);
    }

    const handleApproval = async (status) => {
        const data = {
            YearlyExpenseID: id,
            ApprovalStatus: status,
            ApprovedBy: getLoggedInUser()?.id,
            Comments: Comment
        }

        await post(`yearlyExpense/create-approval`, data);
        getYearlyExpenseById();
    }

    const [reject_modal, setreject_modal] = useState(false);

    const handleRejectClick = () => {
        //setreject_modal(true);
        setreject_modal(!reject_modal);
    };

    const [userRole, setUserRole] = useState('');

    useEffect(() => {
        setUserRole(getLoggedInUserRole());
    }, []);

    const handleRemoveHeadCost = async (id) => {
        await del(`yearlyExpense/delete-headcost/${id}`);
        getYearlyExpenseById();
    }

    return (
        <React.Fragment>

            <Modal isOpen={reject_modal} toggle={() => setreject_modal(!reject_modal)} size="lg">
                <ModalHeader className="mt-0" toggle={() => setreject_modal(!reject_modal)}>Reject Yearly Expense</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={12}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="" className="me-4 w-30">Comment</Label>
                                {/* <Input
                                    type="text"
                                    id=""
                                    placeholder="Type here..."
                                /> */}
                                <textarea
                                    id="comment"
                                    value={Comment}
                                    onChange={(e) => setComment(e.target.value)}
                                    rows="5"
                                    cols="200"
                                    placeholder="Enter your comment here..."
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={12} className="text-end">
                            {/* <button className="btn btn-primary mt-5" onClick={addVendor}>Submit</button> */}
                            <Button color="primary" onClick={() => handleApproval("Rejected")}>Reject</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>

            <div className="page-content">
                <Container className="mt-4" fluid={true} style={{ backgroundColor: "white", padding: "20px" }}>
                    {/* <h5>Single Yearly Expense</h5> */}

                    <Row className="mb-4 mt-4">
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="assignedPS" className="me-4">Budget Year</Label>
                                {(years) && <Input type="select"
                                    placeholder="Enter Type of ID"
                                    id="selectAgency"
                                    onChange={(e) => setBudgetYear(e.target.value)}
                                    value={BudgetYear}
                                    required>
                                    <option selected>Select Year</option>
                                    {years.map(year => (
                                        <option key={year} value={year}>
                                            {year}
                                        </option>
                                    ))}
                                </Input>}
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className="d-flex align-items-center text-nowrap">
                                <Label for="totalExpense" className="me-4">Total Expense</Label>
                                <Input type="text"
                                    placeholder="Total Expense"
                                    id="totalExpense"
                                    onChange={(e) => setTotalExpense(e.target.value)}
                                    value={TotalExpense}
                                    required>
                                </Input>
                            </FormGroup>
                        </Col>
                        {userRole == 'Finance Officer' &&
                            (
                                <Col sm={4}>
                                    <FormGroup className="d-flex align-items-center text-nowrap">
                                        <button onClick={handleSubmit} className="btn btn-primary">Save</button>
                                    </FormGroup>
                                </Col>
                            )
                        }
                    </Row>
                    <Row className={userRole == 'Finance Officer' ? "mb-4 mt-4" : "mb-1 mt-1"}>
                        <h5 className={userRole == 'Finance Officer' ? "mb-4" : "mb-3"}>Head Cost</h5>
                        {/* <Col sm={3}>
                                <FormGroup className="d-flex align-items-center text-nowrap">
                                    <Label for="headCostID" className="me-4">Head Cost ID</Label>
                                    <Input type="text"
                                        placeholder="Head Cost ID"
                                        id="headCostID"
                                        onChange={(e)=> setHcId(e.target.value)}
                                        required>
                                    </Input>
                                </FormGroup>
                            </Col> */}
                        {userRole == 'Finance Officer' &&
                            (
                                <Row>
                                    <Col sm={4}>
                                        <FormGroup className="d-flex align-items-center text-nowrap">
                                            <Label for="headCost" className="me-4">Select Head Cost</Label>
                                            <Input type="select"
                                                placeholder="Head Cost"
                                                id="headCost"
                                                onChange={(e) => setHcName(e.target.value)}
                                                value={hcName}
                                                required>
                                                <option>Select</option>
                                                {headCostList.map((hostCost) => (
                                                    <option value={hostCost}>{hostCost}</option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    </Col>

                                    <Col sm={4}>
                                        <FormGroup className="d-flex align-items-center text-nowrap">
                                            <Label for="amount" className="me-4">Amount</Label>
                                            <Input type="text"
                                                placeholder="Amount"
                                                id="Amount"
                                                onChange={(e) => setHcAmount(e.target.value)}
                                                required>
                                            </Input>
                                        </FormGroup>
                                    </Col>

                                    <Col sm={4}>
                                        <FormGroup className="d-flex align-items-center text-nowrap">
                                            {userRole == 'Finance Officer' && <button className="btn btn-primary" onClick={handleHeadCost}>Add Headcost</button>}
                                        </FormGroup>
                                    </Col>
                                </Row>

                            )
                        }

                    </Row>
                    {costError &&
                        <Alert color="danger">{costError}</Alert>
                    }
                    <Row>
                        <Col sm={6}>
                            <Table bordered responsive>
                                <thead>
                                    <tr>
                                        <th>Head Cost</th>
                                        <th>Amount</th>
                                        {userRole == 'Finance Officer' && (<th>Action</th>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.headCost?.map((hcost) => (
                                        <tr>
                                            <td>{hcost.Name}</td>
                                            <td>${hcost.Amount}</td>
                                            {userRole == 'Finance Officer' && (<td><button className="btn btn-danger" onClick={() => handleRemoveHeadCost(hcost.HeadCostID)}>Delete</button></td>)}
                                        </tr>
                                    ))}
                                    {(data.headCost && data.headCost.length > 0) &&
                                        <>
                                            <tr>
                                                <td><b>Total Budget Summary</b></td>
                                                <td>${totalHeadCost}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Remaining Budget</b></td>
                                                <td>${TotalExpense - totalHeadCost}</td>
                                            </tr>
                                        </>
                                    }
                                </tbody>
                            </Table></Col>
                    </Row>
                    <Row className="mt-3">
                        <h5>Approvals</h5>
                        <Col sm={6}>
                            <Table bordered responsive>
                                <tbody>
                                    <tr>
                                        <td>{data?.approvalUser?.Name}</td>
                                        <td>{data?.ApprovalStatus}</td>
                                        {userRole == 'Permanent Secretary' && (data.ApprovalStatus == "Pending" || data.ApprovalStatus == "Rejected") && <td>
                                            <button className="btn btn-primary flex-fill" onClick={() => handleApproval("Approved")}>Approve</button>
                                            {/* <button className="btn btn-danger mx-2 flex-fill" onClick={() => handleApproval("Rejected")}>Reject</button> */}
                                            <button className="btn btn-danger mx-2 flex-fill" onClick={() => handleRejectClick()}>Reject</button>

                                        </td>}
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    {/* <Row>
                            <Col sm={12}>
                                <FormGroup className="d-flex align-items-center text-nowrap">
                                    <button onClick={handleSubmit} className="btn btn-primary">Save</button>
                                </FormGroup>
                            </Col>
                        </Row> */}

                    <Row>
                        <Col sm={12}>
                            <h5>Approval Logs</h5>
                            <Table bordered responsive>
                                <thead>
                                    <tr>
                                        {/* <th>Log ID</th> */}
                                        <th>Approver Name</th>
                                        <th>Status</th>
                                        <th>Date</th>
                                        <th>Comments</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ApprovalLogs && ApprovalLogs.map((approve) => (
                                        <tr>
                                            {/* <td>{approve.ApprovalLogID}</td> */}
                                            <td>{approve.approver.Name}</td>
                                            <td>{approve.ApprovalStatus}</td>
                                            <td>{formatDateTime(approve.ApprovalDate)}</td>
                                            <td>{approve.Comments}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default UpdateYearlyExpense;
